import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

import { useLocale } from '../../../src/hooks/locale';
import { useScopedSearchParams } from '../../utils/navigation';
import { type FiltersTableDef, TableFilters } from '../table/TableFilters';

import { AdvancedFilters } from './advanced-filters/AdvancedFilters';
import {
  QuickFilter,
  type QuickFilterProps,
} from './quick-filters/QuickFilters';
import { SchemaProvider } from './SchemaProvider';

export type AddWhereClause = (
  where: any,
  schemaPath: string[],
  value: string | string[] | number | boolean | Date | object,
) => any;

const inOperators = ['_in', '_nin'];
const xorOperators = ['_and', '_or'];
const arrayOperators = [...inOperators, ...xorOperators];

export const addWhereClause: AddWhereClause = (where, schemaPath, value) => {
  const [, ...path] = schemaPath;
  const newWhere = JSON.parse(JSON.stringify(where));
  // Initialize the current position to start at the new object
  let current = newWhere;
  // Iterate over the path array, stopping before the last element
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    // If the key doesn't exist at the current path, initialize it as an empty object
    if (!current[key]) {
      if (arrayOperators.includes(key)) {
        current[key] = [];
      } else {
        current[key] = {};
      }
    }
    current = current[key];
  }

  // Set the value at the final key in the path
  const lastKey = path[path.length - 1];
  const penultimateKey = path[path.length - 2];

  // The value at the path is not yet an array, so we can just set the value
  if (arrayOperators.includes(lastKey) && !Array.isArray(current[lastKey])) {
    current[lastKey] = Array.isArray(value) ? value : [value];
  } else if (xorOperators.includes(penultimateKey)) {
    current.push({ [lastKey]: value });
  } else if (inOperators.includes(lastKey)) {
    if (!Array.isArray(value)) {
      current[lastKey].push(value);
    } else {
      current[lastKey] = [...value, ...current[lastKey]];
    }
  } else {
    current[lastKey] = value;
  }

  // Return the updated object, leaving the original `where` object unchanged
  return newWhere;
};

export type DeleteWhereClause = (
  where: any,
  schemaPath: string[],
  deleteEmptyParents?: Boolean,
) => any;

export const deleteWhereClause: DeleteWhereClause = (
  where,
  schemaPath,
  deleteEmptyParents = false,
) => {
  const [, ...path] = schemaPath;
  const newWhere = JSON.parse(JSON.stringify(where));
  let current = newWhere;
  const stack = []; // To keep track of the path if we need to delete empty parents
  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    if (!current[key]) {
      return newWhere;
    }
    stack.push(current);
    current = current[key];
  }
  // If the parent is an object, delete the key
  // If the parent is an array, remove the item
  const lastKey = path[path.length - 1];
  if (Array.isArray(current)) {
    current.splice(parseInt(lastKey), 1);
  } else {
    delete current[lastKey];
  }

  if (deleteEmptyParents) {
    // If the parent object is now empty, delete it as well
    for (let i = path.length - 2; i >= 0; i--) {
      const key = path[i];
      const parent = stack.pop();
      if (Object.keys(parent[key]).length === 0) {
        delete parent[key];
      } else {
        break;
      }
    }
  }
  return newWhere;
};

export interface FilterProps {
  tableName: string;
  quickFilters?: ({
    path: string[];
    label: string;
    shouldDisplay?: (where: {}) => boolean;
    disabled?: (where: {}) => boolean;
    onChange?: (value: any, whereClause: {}) => void | {};
  } & (
    | {
        displayedColumn?: string;
        filter?: QuickFilterProps['filter'];
      }
    | {
        filterComponent: React.ComponentType<
          Omit<
            QuickFilterProps,
            'displayedColumn' | 'filter' | 'getTypeFromPath' | 'type'
          >
        >;
      }
  ))[];
  tableFiltersTables?: FiltersTableDef[];
  expandedFilterType?: 'v1' | 'v2' | 'quick' | null;
  queryParamsScope?: string;
}

export const Filters = ({
  tableName,
  quickFilters,
  tableFiltersTables,
  expandedFilterType,
  queryParamsScope,
}: FilterProps) => {
  const [searchParams] = useScopedSearchParams(queryParamsScope);
  const { dateLocale } = useLocale();
  const baseTypeName = `${tableName}_bool_exp`;
  const whereParam = searchParams.get('where');
  let defaultWhere = {};
  if (whereParam) {
    try {
      defaultWhere = JSON.parse(whereParam);
    } catch (e) {
      console.error('Error parsing where param', e);
    }
  }
  const [where, setWhere] = useState(defaultWhere);

  // useEffect(() => {
  //   setSearchParams({ where: JSON.stringify(where) });
  // }, [where, setSearchParams]);

  useEffect(() => {
    if (!whereParam) {
      return setWhere({});
    }
    try {
      const newWhere = JSON.parse(whereParam);
      setWhere(newWhere);
    } catch (e) {
      console.error('Error parsing where param', e);
    }
    return () => {};
  }, [whereParam]);

  return (
    <SchemaProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateLocale}
      >
        {expandedFilterType === 'quick' && (
          <Box
            sx={{
              maxHeight: '50vh',
              overflowY: 'auto',
              borderTop: '1px solid #ccc',
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              padding: 1,
            }}
          >
            {quickFilters?.map(qf => {
              if (qf.shouldDisplay != null && !qf.shouldDisplay(where)) {
                return null;
              }

              return (
                <div key={qf.path.join('.')}>
                  {'filterComponent' in qf ? (
                    <QuickFilter
                      label={qf.label}
                      path={qf.path}
                      addWhereClause={addWhereClause}
                      deleteWhereClause={deleteWhereClause}
                      CustomComponent={qf.filterComponent}
                      onChange={qf.onChange}
                      queryParamsScope={queryParamsScope}
                      disabled={qf.disabled}
                    />
                  ) : (
                    <QuickFilter
                      label={qf.label}
                      path={qf.path}
                      displayedColumn={qf.displayedColumn}
                      filter={qf.filter}
                      addWhereClause={addWhereClause}
                      deleteWhereClause={deleteWhereClause}
                      onChange={qf.onChange}
                      queryParamsScope={queryParamsScope}
                      disabled={qf.disabled}
                    />
                  )}
                </div>
              );
            })}
          </Box>
        )}

        {expandedFilterType === 'v1' && tableFiltersTables && (
          <TableFilters
            tables={tableFiltersTables}
            queryParamsScope={queryParamsScope}
          />
        )}

        {expandedFilterType === 'v2' && (
          <Box
            sx={{
              maxHeight: '50vh',
              overflowY: 'auto',
              borderTop: '1px solid #ccc',
            }}
          >
            <AdvancedFilters
              where={where}
              setWhere={setWhere}
              baseTypeName={baseTypeName}
              queryParamsScope={queryParamsScope}
            />
          </Box>
        )}
      </LocalizationProvider>
    </SchemaProvider>
  );
};
