import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { NumbersOutlined } from '@mui/icons-material';
import { Box, Button, Paper, TextField, debounce } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { FilterChip } from './FilterChip';
import type { QuickFilterProps } from './QuickFilters';

type NumberFilterProps = Omit<
  QuickFilterProps,
  'filter' | 'displayedColumn' | 'type' | 'getTypeFromPath'
>;

export const NumberFilter = ({
  label,
  addWhereClause,
  deleteWhereClause,
  getValueFromPath,
  where,
  path,
  onChange,
  queryParamsScope,
  disabled,
}: NumberFilterProps) => {
  const { t, locale } = useLocale();
  const defaultMinVal = getValueFromPath([...path, '_gte'], where);
  const defaultMaxVal = getValueFromPath([...path, '_lte'], where);
  const isDirty = useRef(false);

  const [min, setMin] = useState<number | null>(defaultMinVal);
  const [max, setMax] = useState<number | null>(defaultMaxVal);
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);

  useEffect(() => {
    if (defaultMinVal == null) {
      setMin(null);
    }

    if (defaultMaxVal == null) {
      setMax(null);
    }
  }, [defaultMinVal, defaultMaxVal]);

  const computedLabel = useMemo(() => {
    if (max != null && min == null) {
      return `${label}: ≤ ${max.toLocaleString(locale)}`;
    }

    if (max == null && min != null) {
      return `${label}: ≥ ${min.toLocaleString(locale)}`;
    }

    if (max != null && min != null) {
      return `${label}: ${min.toLocaleString(locale)} - ${max.toLocaleString(
        locale,
      )}`;
    }

    return label;
  }, [label, min, max, locale]);

  const apply = useCallback(
    (minVal: number | null, maxVal: number | null) => {
      let newWhere = deleteWhereClause(where, path, true);
      if (minVal != null) {
        newWhere = addWhereClause(newWhere, [...path, '_gte'], minVal);
      }
      if (maxVal != null) {
        newWhere = addWhereClause(newWhere, [...path, '_lte'], maxVal);
      }

      setFiltersParams(
        onChange?.({ min: minVal, max: maxVal }, newWhere) ?? newWhere,
      );
    },
    [
      where,
      path,
      addWhereClause,
      deleteWhereClause,
      setFiltersParams,
      onChange,
    ],
  );

  const debouncedOnChange = useMemo(() => {
    return debounce((minVal: number | null, maxVal: number | null) => {
      isDirty.current = false;
      apply(minVal, maxVal);
    }, 500);
  }, [apply]);

  const clear = () => {
    let newWhere = where;
    setMin(null);
    setMax(null);
    newWhere = deleteWhereClause(newWhere, path, true);
    setFiltersParams(
      onChange?.({ min: null, max: null }, newWhere) ?? newWhere,
    );
  };

  const applyIfDirty = useCallback(() => {
    // Prevents triggering apply if already done through onChange debounce
    if (isDirty.current) {
      // Clears queued on change
      debouncedOnChange.clear();
      isDirty.current = false;
      apply(min, max);
    }
  }, [debouncedOnChange, apply, min, max]);

  return (
    <>
      <FilterChip
        label={computedLabel}
        icon={<NumbersOutlined />}
        onDelete={min || max ? clear : undefined}
        disabled={disabled?.(where) ?? false}
        renderFilter={({ handleClose }) => (
          <Paper>
            <form
              onSubmit={e => {
                e.preventDefault();
                applyIfDirty();
                handleClose();
              }}
            >
              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 1,
                }}
              >
                <TextField
                  autoFocus
                  variant="outlined"
                  placeholder="min"
                  type="number"
                  size="small"
                  onBlur={applyIfDirty}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newMin = isNaN(e.target.valueAsNumber)
                      ? null
                      : e.target.valueAsNumber;

                    isDirty.current = true;
                    setMin(newMin);
                    debouncedOnChange(newMin, max);
                  }}
                  value={min}
                  sx={{ minWidth: 150 }}
                />
                <TextField
                  variant="outlined"
                  placeholder="max"
                  type="number"
                  size="small"
                  onBlur={applyIfDirty}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newMax = isNaN(e.target.valueAsNumber)
                      ? null
                      : e.target.valueAsNumber;

                    isDirty.current = true;
                    setMax(newMax);
                    debouncedOnChange(min, newMax);
                  }}
                  value={max}
                  sx={{ minWidth: 150 }}
                />
                <Button
                  onClick={() => {
                    applyIfDirty();
                    handleClose();
                  }}
                  size="small"
                  type="submit"
                >
                  {t('Apply')}
                </Button>
              </Box>
            </form>
          </Paper>
        )}
      />
    </>
  );
};
