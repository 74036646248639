// @flow

import * as React from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form, useFormik } from '@realadvisor/form';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, Flex, useSystem } from 'react-system';

import { CounterInput } from '../controls/counter-input';
import { CountryInput } from '../controls/country-input';
import { DateInput, formatDate, parseDate } from '../controls/date-input';
import { LocationMarker, Mapbox } from '../controls/mapbox';
import {
  CubeInput,
  EmissionsInput,
  EnergyConsumptionInput,
  MeterInput,
  SquareInput,
} from '../controls/meter-input';
import { PriceInput } from '../controls/price-input';
import { ScrollToError } from '../controls/scroll-to-error';
import { YearInput } from '../controls/year-input';
import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { getDefaultLocationOnMap } from '../locale';
import { AddressInput } from '../shared/address-input';
import { toISODateString } from '../utils/date-utils';

import type {
  PropertyCooling,
  PropertyForm_property$key,
  PropertyHeatingDistribution,
  PropertyOrientation,
} from './__generated__/PropertyForm_property.graphql';
import type { PropertyForm_root$key } from './__generated__/PropertyForm_root.graphql';
import type {
  PropertyFormUpdateMutation,
  PropertyInput,
} from './__generated__/PropertyFormUpdateMutation.graphql';

type Props = {|
  root: PropertyForm_root$key,
  property: ?PropertyForm_property$key,
  holder?: 'ground' | 'modal' | 'big-modal',
  handleSubmit?: (PropertyInput, PropertyFormState) => void,
  initialValues?: $Shape<PropertyFormState>,
  disabledFields?: $ReadOnlyArray<$Keys<PropertyFormState>>,
  disableValidation?: boolean,
  showAppraisalAlert?: boolean,
  retry?: () => void,
  children: ({|
    form: React.Node,
    submitForm: () => void,
    resetForm: () => void,
    changed: boolean,
    valid: boolean,
    submitting: boolean,
  |}) => React.Node,
|};

export type PropertyFormState = {|
  id: ?string,
  lat: ?number,
  lng: ?number,
  route: ?string,
  streetNumber: ?string,
  postcode: ?string,
  state: ?string,
  locality: ?string,
  countryCode: ?string,
  mainType: ?string,
  propertyTypeId: ?string,
  propertyTypeName: ?string,

  microLocation: string,
  macroLocation: string,
  residentialSurface: string,
  commercialSurface: string,
  livingSurface: string,
  builtSurface: string,
  usableSurface: string,
  landSurface: string,
  balconySurface: string,
  gardenSurface: string,
  terraceSurface: string,
  grossFloorSurface: string,
  weightedFloorSurface: string,
  basementSurface: string,
  numberOfRooms: string,
  numberOfBedrooms: string,
  numberOfBathrooms: string,
  numberOfToilets: string,
  numberOfIndoorParkings: string,
  numberOfOutdoorParkings: string,
  numberOfFloors: string,
  floorOfFlat: string,
  side: ?string,
  charges: string,
  renovationFund: string,
  orientation: ?PropertyOrientation,

  heating: ?string,
  heatingDistribution: ?PropertyHeatingDistribution,
  cooling: ?PropertyCooling,

  distanceToPublicTransport: string,
  distanceToGroceryShop: string,
  distanceToKindergarten: string,
  distanceToPrimarySchool: string,
  distanceToHighSchool: string,
  distanceToMotorway: string,
  distanceToHospital: string,
  distanceToCityCenter: string,
  distanceToAirport: string,
  distanceToTrainStation: string,
  distanceToSportsCenter: string,

  constructionYear: string,
  renovationYear: string,
  buildingStandard: string,
  buildingCondition: string,
  minergieCode: string,
  buildingVolume: string,

  numberOfResidentialUnits: string,
  numberOfCommercialUnits: string,
  isResidentialFullyRented: boolean,
  isCommercialFullyRented: boolean,
  residentialPotentialRentalIncome: string,
  residentialYearlyRentalIncome: string,
  commercialPotentialRentalIncome: string,
  commercialYearlyRentalIncome: string,
  incomeIncludesParking: boolean,
  isParkingFullyRented: boolean,
  parkingPotentialRentalIncome: string,
  parkingYearlyRentalIncome: string,

  hasElevator: boolean,
  hasFireplace: boolean,
  hasSwimmingPool: boolean,
  hasStorageRoom: boolean,
  hasCellar: boolean,
  hasAttic: boolean,
  hasPhotovoltaicPanels: boolean,
  hasSauna: boolean,
  hasGym: boolean,
  hasWellnessArea: boolean,
  isLuxury: boolean,
  hasView: boolean,
  isWheelchairAccessible: boolean,
  isChildFriendly: boolean,
  plotNumber: ?string,

  emissions?: ?string,
  consumption?: ?string,
  energyReferenceYear?: ?string,
  estimatedLowerEnergyCost?: ?string,
  estimatedHigherEnergyCost?: ?string,
  energyCertificateVersion?: ?string,
  energyInspectionDate?: ?string,
  energyConsumptionClass?: ?string,
  gasEmissionsClass?: ?string,
|};

const string_of_number = (number: ?number) =>
  number != null ? String(number) : '';

const number_of_string = (string: string) => {
  const parsed = Number.parseFloat(string);
  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed;
};

const SectionHeading = ({ children }) => {
  const { text } = useTheme();

  return (
    <Box pb={2} css={text.subtitle2}>
      {children}
    </Box>
  );
};

const getInitialValues = (property, initialValues): PropertyFormState => ({
  id: property?.id,
  lat: initialValues?.lat ?? property?.lat,
  lng: initialValues?.lng ?? property?.lng,
  route: initialValues?.route ?? property?.route,
  streetNumber: initialValues?.streetNumber ?? property?.streetNumber,
  state: initialValues?.state ?? property?.state,
  postcode: initialValues?.postcode ?? property?.postcode,
  countryCode: initialValues?.countryCode ?? property?.countryCode,
  locality: initialValues?.locality ?? property?.locality,
  mainType: initialValues?.mainType ?? property?.propertyType?.mainType,
  propertyTypeId: initialValues?.propertyTypeId ?? property?.propertyType?.id,
  propertyTypeName:
    initialValues?.propertyTypeName ?? property?.propertyType?.name,
  numberOfRooms:
    initialValues?.numberOfRooms ?? string_of_number(property?.numberOfRooms),
  numberOfBedrooms:
    initialValues?.numberOfBedrooms ??
    string_of_number(property?.numberOfBedrooms),
  numberOfBathrooms:
    initialValues?.numberOfBathrooms ??
    string_of_number(property?.numberOfBathrooms),
  numberOfToilets:
    initialValues?.numberOfToilets ??
    string_of_number(property?.numberOfToilets),
  livingSurface:
    initialValues?.livingSurface ?? string_of_number(property?.livingSurface),
  builtSurface:
    initialValues?.builtSurface ?? string_of_number(property?.builtSurface),
  usableSurface:
    initialValues?.usableSurface ?? string_of_number(property?.usableSurface),
  landSurface:
    initialValues?.landSurface ?? string_of_number(property?.landSurface),
  constructionYear:
    initialValues?.constructionYear ??
    string_of_number(property?.constructionYear),

  microLocation:
    initialValues?.microLocation ?? string_of_number(property?.microLocation),
  macroLocation:
    initialValues?.macroLocation ?? string_of_number(property?.macroLocation),
  residentialSurface:
    initialValues?.residentialSurface ??
    string_of_number(property?.residentialSurface),
  commercialSurface:
    initialValues?.commercialSurface ??
    string_of_number(property?.commercialSurface),
  balconySurface:
    initialValues?.balconySurface ?? string_of_number(property?.balconySurface),
  gardenSurface:
    initialValues?.gardenSurface ?? string_of_number(property?.gardenSurface),
  basementSurface:
    initialValues?.basementSurface ??
    string_of_number(property?.basementSurface),
  terraceSurface:
    initialValues?.terraceSurface ?? string_of_number(property?.terraceSurface),
  grossFloorSurface:
    initialValues?.grossFloorSurface ??
    string_of_number(property?.grossFloorSurface),
  weightedFloorSurface:
    initialValues?.weightedFloorSurface ??
    string_of_number(property?.weightedFloorSurface),
  numberOfIndoorParkings:
    initialValues?.numberOfIndoorParkings ??
    string_of_number(property?.numberOfIndoorParkings),
  numberOfOutdoorParkings:
    initialValues?.numberOfOutdoorParkings ??
    string_of_number(property?.numberOfOutdoorParkings),
  numberOfFloors:
    initialValues?.numberOfFloors ?? string_of_number(property?.numberOfFloors),
  floorOfFlat:
    initialValues?.floorOfFlat ?? string_of_number(property?.floorOfFlat),
  side: initialValues?.side ?? property?.side,
  orientation: initialValues?.orientation ?? property?.orientation,

  distanceToPublicTransport:
    initialValues?.distanceToPublicTransport ??
    string_of_number(property?.distanceToPublicTransport),
  distanceToGroceryShop:
    initialValues?.distanceToGroceryShop ??
    string_of_number(property?.distanceToGroceryShop),
  distanceToKindergarten:
    initialValues?.distanceToKindergarten ??
    string_of_number(property?.distanceToKindergarten),
  distanceToPrimarySchool:
    initialValues?.distanceToPrimarySchool ??
    string_of_number(property?.distanceToPrimarySchool),
  distanceToHighSchool:
    initialValues?.distanceToHighSchool ??
    string_of_number(property?.distanceToHighSchool),
  distanceToMotorway:
    initialValues?.distanceToMotorway ??
    string_of_number(property?.distanceToMotorway),
  distanceToHospital:
    initialValues?.distanceToHospital ??
    string_of_number(property?.distanceToHospital),
  distanceToCityCenter:
    initialValues?.distanceToCityCenter ??
    string_of_number(property?.distanceToCityCenter),
  distanceToAirport:
    initialValues?.distanceToAirport ??
    string_of_number(property?.distanceToAirport),
  distanceToTrainStation:
    initialValues?.distanceToTrainStation ??
    string_of_number(property?.distanceToTrainStation),
  distanceToSportsCenter:
    initialValues?.distanceToSportsCenter ??
    string_of_number(property?.distanceToSportsCenter),

  heating: initialValues?.heating ?? property?.heating,
  heatingDistribution:
    initialValues?.heatingDistribution ?? property?.heatingDistribution,
  cooling: initialValues?.cooling ?? property?.cooling,

  charges: initialValues?.charges ?? string_of_number(property?.charges),
  renovationFund:
    initialValues?.renovationFund ?? string_of_number(property?.renovationFund),

  renovationYear:
    initialValues?.renovationYear ?? string_of_number(property?.renovationYear),
  buildingStandard:
    initialValues?.buildingStandard ??
    string_of_number(property?.buildingStandard),
  buildingCondition:
    initialValues?.buildingCondition ??
    string_of_number(property?.buildingCondition),
  minergieCode:
    initialValues?.minergieCode ?? string_of_number(property?.minergieCode),
  buildingVolume:
    initialValues?.buildingVolume ?? string_of_number(property?.buildingVolume),
  numberOfResidentialUnits:
    initialValues?.numberOfResidentialUnits ??
    string_of_number(property?.numberOfResidentialUnits),
  numberOfCommercialUnits:
    initialValues?.numberOfCommercialUnits ??
    string_of_number(property?.numberOfCommercialUnits),
  isResidentialFullyRented:
    initialValues?.isResidentialFullyRented ??
    property?.isResidentialFullyRented === true,
  isCommercialFullyRented:
    initialValues?.isCommercialFullyRented ??
    property?.isCommercialFullyRented === true,
  residentialPotentialRentalIncome:
    initialValues?.residentialPotentialRentalIncome ??
    string_of_number(property?.residentialPotentialRentalIncome),
  residentialYearlyRentalIncome:
    initialValues?.residentialYearlyRentalIncome ??
    string_of_number(property?.residentialYearlyRentalIncome),
  commercialPotentialRentalIncome:
    initialValues?.commercialPotentialRentalIncome ??
    string_of_number(property?.commercialPotentialRentalIncome),
  commercialYearlyRentalIncome:
    initialValues?.commercialYearlyRentalIncome ??
    string_of_number(property?.commercialYearlyRentalIncome),
  incomeIncludesParking:
    initialValues?.incomeIncludesParking ??
    property?.incomeIncludesParking === true,
  isParkingFullyRented:
    initialValues?.isParkingFullyRented ??
    property?.isParkingFullyRented === true,
  parkingPotentialRentalIncome:
    initialValues?.parkingPotentialRentalIncome ??
    string_of_number(property?.parkingPotentialRentalIncome),
  parkingYearlyRentalIncome:
    initialValues?.parkingYearlyRentalIncome ??
    string_of_number(property?.parkingYearlyRentalIncome),
  hasElevator: initialValues?.hasElevator ?? property?.hasElevator === true,
  hasFireplace: initialValues?.hasFireplace ?? property?.hasFireplace === true,
  hasSwimmingPool:
    initialValues?.hasSwimmingPool ?? property?.hasSwimmingPool === true,
  hasStorageRoom:
    initialValues?.hasStorageRoom ?? property?.hasStorageRoom === true,
  hasCellar: initialValues?.hasCellar ?? property?.hasCellar === true,
  hasAttic: initialValues?.hasAttic ?? property?.hasAttic === true,
  hasPhotovoltaicPanels:
    initialValues?.hasPhotovoltaicPanels ??
    property?.hasPhotovoltaicPanels === true,
  hasSauna: initialValues?.hasSauna ?? property?.hasSauna === true,
  hasGym: initialValues?.hasGym ?? property?.hasGym === true,
  hasWellnessArea:
    initialValues?.hasWellnessArea ?? property?.hasWellnessArea === true,
  isLuxury: initialValues?.isLuxury ?? property?.isLuxury === true,
  hasView: initialValues?.hasView ?? property?.hasView === true,
  isWheelchairAccessible:
    initialValues?.isWheelchairAccessible ??
    property?.isWheelchairAccessible === true,
  isChildFriendly:
    initialValues?.isChildFriendly ?? property?.isChildFriendly === true,
  plotNumber: initialValues?.plotNumber ?? property?.plotNumber,

  emissions: initialValues?.emissions ?? string_of_number(property?.emissions),
  consumption:
    initialValues?.consumption ?? string_of_number(property?.consumption),
  energyReferenceYear:
    initialValues?.energyReferenceYear ??
    string_of_number(property?.energyReferenceYear),
  estimatedLowerEnergyCost:
    initialValues?.estimatedLowerEnergyCost ??
    string_of_number(property?.estimatedLowerEnergyCost),
  estimatedHigherEnergyCost:
    initialValues?.estimatedHigherEnergyCost ??
    string_of_number(property?.estimatedHigherEnergyCost),
  energyCertificateVersion:
    initialValues?.energyCertificateVersion ??
    property?.energyCertificateVersion ??
    '',
  energyInspectionDate:
    initialValues?.energyInspectionDate == null &&
    property?.energyInspectionDate == null
      ? ''
      : formatDate(
          new Date(
            initialValues?.energyInspectionDate ??
              property?.energyInspectionDate ??
              '',
          ),
        ),
  energyConsumptionClass:
    initialValues?.energyConsumptionClass ?? property?.energyConsumptionClass,
  gasEmissionsClass:
    initialValues?.gasEmissionsClass ?? property?.gasEmissionsClass,
});

export const LIVING_SURFACE_MAX = 5000;
export const LIVING_SURFACE_MIN = 15;
export const BUILT_SURFACE_MAX = 5000;
export const BUILT_SURFACE_MIN = 15;
export const USABLE_SURFACE_MAX = 5000;
export const USABLE_SURFACE_MIN = 0;
export const LAND_SURFACE_MAX = 50000;
export const LAND_SURFACE_MIN = 0;
export const BALCONY_SURFACE_MAX = 1000;
export const BALCONY_SURFACE_MIN = 0;
export const GARDEN_SURFACE_MAX = 50000;
export const GARDEN_SURFACE_MIN = 0;
export const TERRACE_SURFACE_MAX = 1000;
export const TERRACE_SURFACE_MIN = 0;
export const WEIGHTED_FLOOR_SURFACE_MAX = 50000;
export const WEIGHTED_FLOOR_SURFACE_MIN = 0;
export const BASEMENT_SURFACE_MAX = 1000;
export const BASEMENT_SURFACE_MIN = 0;
export const GROSS_FLOOR_SURFACE_MAX = 50000;
export const GROSS_FLOOR_SURFACE_MIN = 0;
export const RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN = 1;
export const ENERGY_CONSUMPTION_MIN = 1;
export const ENERGY_CONSUMPTION_MAX = 10_000;
export const ESTIMATED_COST_MIN = 1;
export const ESTIMATED_COST_MAX = 1_000_000;

const isEmpty = value =>
  value == null || (typeof value === 'string' && value.length === 0);

const outOfRange = (value, min, max) => {
  const number = number_of_string(value);
  return number == null || number < min || number > max;
};

export const getAppraisalRequirements = (
  values: $ReadOnly<$Shape<PropertyFormState>>,
  disableValidation?: boolean,
): $ReadOnlyArray<$Keys<PropertyFormState>> => {
  const fields = ['mainType', 'propertyTypeId'];

  if (disableValidation === true) {
    return fields;
  }

  if (values.mainType !== 'PROP') {
    fields.push('constructionYear');
  }

  if (
    ![
      'indus_commercial_and_residential',
      'house_multiple_dwelling',
      'indus_commercial',
    ].includes(values.propertyTypeName)
  ) {
    return fields;
  }

  fields.push(
    'landSurface',
    'incomeIncludesParking',
    'numberOfIndoorParkings',
    'numberOfOutdoorParkings',
  );

  if (values.incomeIncludesParking === false) {
    fields.push('parkingYearlyRentalIncome');

    if (values.isParkingFullyRented !== true) {
      fields.push('parkingPotentialRentalIncome');
    }
  }

  // resitential
  if (
    ['indus_commercial_and_residential', 'house_multiple_dwelling'].includes(
      values.propertyTypeName,
    )
  ) {
    fields.push(
      'residentialSurface',
      'numberOfResidentialUnits',
      'isResidentialFullyRented',
      'residentialYearlyRentalIncome',
    );

    if (values.isResidentialFullyRented !== true) {
      fields.push('residentialPotentialRentalIncome');
    }
  }

  // commercial
  if (
    ['indus_commercial_and_residential', 'indus_commercial'].includes(
      values.propertyTypeName,
    )
  ) {
    fields.push(
      'commercialSurface',
      'numberOfCommercialUnits',
      'isCommercialFullyRented',
      'commercialYearlyRentalIncome',
    );

    if (values.isCommercialFullyRented !== true) {
      fields.push('commercialPotentialRentalIncome');
    }
  }

  return fields;
};

const validate = ({ values, t, isEsTenant, disableValidation }) => {
  const propertyTypeName = values.propertyTypeName;
  const isMultiFamily = [
    'indus_commercial_and_residential',
    'house_multiple_dwelling',
  ].includes(propertyTypeName);
  const isCommercial = [
    'indus_commercial_and_residential',
    'indus_commercial',
  ].includes(propertyTypeName);
  const isLand = values.mainType === 'PROP';
  const errors = {};

  if (isEmpty(values.route)) {
    errors.route = t('addressIsRequired');
  }
  if (isEmpty(values.countryCode)) {
    errors.countryCode = t('countryIsRequired');
  }
  if (isEmpty(values.state)) {
    errors.state = t('stateIsRequired');
  }
  if (
    values.livingSurface.length !== 0 &&
    outOfRange(values.livingSurface, LIVING_SURFACE_MIN, LIVING_SURFACE_MAX)
  ) {
    errors.livingSurface = t('livingSurfaceMustBeInRange', {
      min: LIVING_SURFACE_MIN,
      max: LIVING_SURFACE_MAX,
    });
  }

  if (
    values.builtSurface.length !== 0 &&
    outOfRange(values.builtSurface, BUILT_SURFACE_MIN, BUILT_SURFACE_MAX)
  ) {
    errors.builtSurface = t('builtSurfaceMustBeInRange', {
      min: BUILT_SURFACE_MIN,
      max: BUILT_SURFACE_MAX,
    });
  }

  if (
    values.usableSurface.length !== 0 &&
    outOfRange(values.usableSurface, 0, 5000)
  ) {
    errors.usableSurface = t('usableSurfaceMustBeInRange', {
      min: USABLE_SURFACE_MIN,
      max: USABLE_SURFACE_MAX,
    });
  }

  if (
    values.residentialSurface.length !== 0 &&
    outOfRange(
      values.residentialSurface,
      RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
      LAND_SURFACE_MAX,
    )
  ) {
    errors.residentialSurface = t('residentialSurfaceMustBeInRange', {
      min: RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
      max: LAND_SURFACE_MAX,
    });
  }

  if (
    values.commercialSurface.length !== 0 &&
    outOfRange(
      values.commercialSurface,
      RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
      LAND_SURFACE_MAX,
    )
  ) {
    errors.commercialSurface = t('commercialSurfaceMustBeInRange', {
      min: RESIDENTAL_AND_COMMERCIAL_SURFACES_MIN,
      max: LAND_SURFACE_MAX,
    });
  }

  if (
    values.landSurface.length !== 0 &&
    outOfRange(values.landSurface, 0, 50000)
  ) {
    errors.landSurface = t('landSurfaceMustBeInRange', {
      min: LAND_SURFACE_MIN,
      max: LAND_SURFACE_MAX,
    });
  }

  if (
    values.balconySurface.length !== 0 &&
    outOfRange(values.balconySurface, 0, 1000)
  ) {
    errors.balconySurface = t('balconySurfaceMustBeInRange', {
      min: BALCONY_SURFACE_MIN,
      max: BALCONY_SURFACE_MAX,
    });
  }

  if (
    values.gardenSurface.length !== 0 &&
    outOfRange(values.gardenSurface, 0, 50000)
  ) {
    errors.gardenSurface = t('gardenSurfaceMustBeInRange', {
      min: GARDEN_SURFACE_MIN,
      max: GARDEN_SURFACE_MAX,
    });
  }

  if (
    values.basementSurface.length !== 0 &&
    outOfRange(values.basementSurface, 0, 1000)
  ) {
    errors.basementSurface = t('basementSurfaceMustBeInRange', {
      min: BASEMENT_SURFACE_MIN,
      max: BASEMENT_SURFACE_MAX,
    });
  }

  if (
    values.terraceSurface.length !== 0 &&
    outOfRange(values.terraceSurface, 0, 1000)
  ) {
    errors.terraceSurface = t('terraceSurfaceMustBeInRange', {
      min: TERRACE_SURFACE_MIN,
      max: TERRACE_SURFACE_MAX,
    });
  }

  if (
    values.weightedFloorSurface.length !== 0 &&
    outOfRange(values.weightedFloorSurface, 0, 50000)
  ) {
    errors.weightedFloorSurface = t('weightedFloorSurfaceMustBeInRange', {
      min: WEIGHTED_FLOOR_SURFACE_MIN,
      max: WEIGHTED_FLOOR_SURFACE_MAX,
    });
  }

  if (
    values.grossFloorSurface.length !== 0 &&
    outOfRange(values.grossFloorSurface, 0, 50000)
  ) {
    errors.grossFloorSurface = t('grossFloorSurfaceMustBeInRange', {
      min: GROSS_FLOOR_SURFACE_MIN,
      max: GROSS_FLOOR_SURFACE_MAX,
    });
  }

  if (
    values.constructionYear.length !== 0 &&
    outOfRange(values.constructionYear, 1000, 2040)
  ) {
    errors.constructionYear = t('constructionYearMustBeInRange', {
      min: 1000,
      max: 2040,
    });
  }

  if (
    values.renovationYear.length !== 0 &&
    outOfRange(values.renovationYear, 1000, 2040)
  ) {
    errors.renovationYear = t('renovationYearMustBeInRange', {
      min: 1000,
      max: 2040,
    });
  }

  const renovationYearToNumber =
    number_of_string(values.renovationYear ?? '') ?? 0;
  const constructionYearToNumber =
    number_of_string(values.constructionYear ?? '') ?? 0;
  if (
    values.renovationYear.length !== 0 &&
    values.constructionYear.length !== 0 &&
    renovationYearToNumber < constructionYearToNumber
  ) {
    errors.renovationYear = t('renovationYearCannotBeBeforeConstructionYear');
  }

  if (
    values.numberOfResidentialUnits.length !== 0 &&
    outOfRange(values.numberOfResidentialUnits, 0, 500)
  ) {
    errors.numberOfResidentialUnits = t(
      'numberOfResidentialUnitsMustBeInRange',
      {
        min: 0,
        max: 500,
      },
    );
  }

  if (
    values.numberOfCommercialUnits.length !== 0 &&
    outOfRange(values.numberOfCommercialUnits, 0, 500)
  ) {
    errors.numberOfCommercialUnits = t('numberOfCommercialUnitsMustBeInRange', {
      min: 0,
      max: 500,
    });
  }

  const MIN_ROOMS = values.mainType === 'PROP' ? 0 : 1;

  if (
    values.numberOfRooms.length !== 0 &&
    outOfRange(values.numberOfRooms, MIN_ROOMS, 50)
  ) {
    errors.numberOfRooms = t('numberOfRoomsMustBeInRange', {
      min: MIN_ROOMS,
      max: 50,
    });
  }

  if (
    values.numberOfBedrooms.length !== 0 &&
    outOfRange(values.numberOfBedrooms, 0, 50)
  ) {
    errors.numberOfBedrooms = t('numberOfBedroomsMustBeInRange', {
      min: 0,
      max: 50,
    });
  }

  if (
    values.numberOfBathrooms.length !== 0 &&
    outOfRange(values.numberOfBathrooms, 0, 20)
  ) {
    errors.numberOfBathrooms = t('numberOfBathroomsMustBeInRange', {
      min: 0,
      max: 20,
    });
  }

  if (
    values.numberOfToilets.length !== 0 &&
    outOfRange(values.numberOfToilets, 0, 30)
  ) {
    errors.numberOfToilets = t('numberOfToiletsMustBeInRange', {
      min: 0,
      max: 30,
    });
  }

  if (
    values.microLocation.length !== 0 &&
    outOfRange(values.microLocation, 1, 5)
  ) {
    errors.microLocation = t('microLocationMustBeInRange', {
      min: 1,
      max: 5,
    });
  }

  if (
    values.macroLocation.length !== 0 &&
    outOfRange(values.macroLocation, 1, 5)
  ) {
    errors.macroLocation = t('macroLocationMustBeInRange', {
      min: 1,
      max: 5,
    });
  }

  if (
    values.buildingVolume.length !== 0 &&
    outOfRange(values.buildingVolume, 40, 20000)
  ) {
    errors.buildingVolume = t('buildingVolumeMustBeInRange', {
      min: 40,
      max: 20000,
    });
  }

  if (
    values.consumption?.length !== 0 &&
    outOfRange(
      values.consumption ?? '',
      ENERGY_CONSUMPTION_MIN,
      ENERGY_CONSUMPTION_MAX,
    )
  ) {
    errors.consumption = t('consumptionBetweenError', {
      min: ENERGY_CONSUMPTION_MIN,
      max: ENERGY_CONSUMPTION_MAX,
    });
  }

  if (
    values.emissions?.length !== 0 &&
    outOfRange(
      values.emissions ?? '',
      ENERGY_CONSUMPTION_MIN,
      ENERGY_CONSUMPTION_MAX,
    )
  ) {
    errors.emissions = t('emissionsBetweenError', {
      min: ENERGY_CONSUMPTION_MIN,
      max: ENERGY_CONSUMPTION_MAX,
    });
  }

  if (
    values.estimatedLowerEnergyCost?.length !== 0 &&
    outOfRange(
      values.estimatedLowerEnergyCost ?? '',
      ESTIMATED_COST_MIN,
      ESTIMATED_COST_MAX,
    )
  ) {
    errors.estimatedLowerEnergyCost = t('minEstimatedBetweenError', {
      min: ESTIMATED_COST_MIN,
      max: ESTIMATED_COST_MAX,
    });
  }

  if (
    values.estimatedHigherEnergyCost?.length !== 0 &&
    outOfRange(
      values.estimatedHigherEnergyCost ?? '',
      ESTIMATED_COST_MIN,
      ESTIMATED_COST_MAX,
    )
  ) {
    errors.estimatedHigherEnergyCost = t('maxEstimatedBetweenError', {
      min: ESTIMATED_COST_MIN,
      max: ESTIMATED_COST_MAX,
    });
  }

  if (
    values.estimatedLowerEnergyCost?.length !== 0 &&
    values.estimatedHigherEnergyCost?.length !== 0 &&
    (number_of_string(values.estimatedLowerEnergyCost ?? '') ?? 0) >
      (number_of_string(values.estimatedHigherEnergyCost ?? '') ?? 0)
  ) {
    errors.estimatedHigherEnergyCost = t(
      'minEstimatedCostCannotExceedMaxError',
    );
    errors.estimatedLowerEnergyCost = t('minEstimatedCostCannotExceedMaxError');
  }

  if (values.mainType != null && values.propertyTypeId == null) {
    errors.propertyTypeId = t('typeIsRequired');
  }

  if (!disableValidation) {
    if (
      ['HOUSE', 'APPT'].includes(values.mainType) &&
      values.propertyTypeName !== 'house_multiple_dwelling'
    ) {
      if (isEmpty(values.constructionYear)) {
        errors.constructionYear = t('Field is required');
      }
      if (isEsTenant) {
        if (isEmpty(values.builtSurface)) {
          errors.builtSurface = t('Field is required');
        }
        if (isEmpty(values.numberOfBedrooms)) {
          errors.numberOfBedrooms = t('Field is required');
        }
      } else {
        if (isEmpty(values.livingSurface)) {
          errors.livingSurface = t('Field is required');
        }
        if (isEmpty(values.numberOfRooms)) {
          errors.numberOfRooms = t('Field is required');
        }
      }
    }

    if (
      values.mainType === 'PROP' ||
      values.propertyTypeName === 'house_multiple_dwelling'
    ) {
      if (isEmpty(values.landSurface)) {
        errors.landSurface = t('Field is required');
      }
    }

    if (isLand && (values.plotNumber?.length ?? 0) > 50) {
      errors.plotNumber = t('plotNumberCharactersExceeded');
    }
  }

  const residentialPotentialRentalIncome = number_of_string(
    values.residentialPotentialRentalIncome,
  );
  const residentialYearlyRentalIncome = number_of_string(
    values.residentialYearlyRentalIncome,
  );

  if (
    isMultiFamily &&
    values.isResidentialFullyRented === false &&
    residentialPotentialRentalIncome != null &&
    residentialYearlyRentalIncome != null &&
    residentialPotentialRentalIncome <= residentialYearlyRentalIncome
  ) {
    errors.residentialPotentialRentalIncome = t('potentialIncomeTooLow');
  }

  const commercialPotentialRentalIncome = number_of_string(
    values.commercialPotentialRentalIncome,
  );
  const commercialYearlyRentalIncome = number_of_string(
    values.commercialYearlyRentalIncome,
  );

  if (
    isCommercial &&
    values.isCommercialFullyRented === false &&
    commercialPotentialRentalIncome != null &&
    commercialYearlyRentalIncome != null &&
    commercialPotentialRentalIncome <= commercialYearlyRentalIncome
  ) {
    errors.commercialPotentialRentalIncome = t('potentialIncomeTooLow');
  }

  const parkingPotentialRentalIncome = number_of_string(
    values.parkingPotentialRentalIncome,
  );
  const parkingYearlyRentalIncome = number_of_string(
    values.parkingYearlyRentalIncome,
  );

  if (
    (isMultiFamily || isCommercial) &&
    values.isParkingFullyRented === false &&
    parkingPotentialRentalIncome != null &&
    parkingYearlyRentalIncome != null &&
    parkingPotentialRentalIncome <= parkingYearlyRentalIncome
  ) {
    errors.parkingPotentialRentalIncome = t('potentialIncomeTooLow');
  }

  const appraisalRequirements = getAppraisalRequirements(
    values,
    disableValidation,
  );
  for (const field of appraisalRequirements) {
    if (
      (values[field] == null || values[field] === '') &&
      errors[field] == null
    ) {
      errors[field] = t('Field is required');
    }
  }

  const numberOfFloors = number_of_string(values.numberOfFloors);
  const floorOfFlat = number_of_string(values.floorOfFlat);
  if (
    numberOfFloors != null &&
    floorOfFlat != null &&
    floorOfFlat > numberOfFloors
  ) {
    errors.floorOfFlat = t(
      'Floor of flat cannot be greater than number of floors',
    );
  }

  return errors;
};

const AddressLocationMap = ({ formik }) => {
  const { countryCode } = useLocale();
  const defaultLocation = getDefaultLocationOnMap(countryCode);
  const lat = formik.values.lat ?? defaultLocation.lat;
  const lng = formik.values.lng ?? defaultLocation.lng;
  const mapRef = React.useRef(null);

  return (
    <>
      <Mapbox
        mapRef={mapRef}
        mapStyle="mapbox://styles/mapbox/light-v9"
        lat={lat}
        lng={lng}
        zoom={lat != null && lng != null ? 12 : 0}
      />
      {formik.values.lat != null && formik.values.lng != null && (
        <LocationMarker mapRef={mapRef} lat={lat} lng={lng} />
      )}
    </>
  );
};

const AddressForm = ({ formik }) => {
  const { t, countryCode } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;
  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
      })}
    >
      {values.id != null && values.lat == null && values.lng == null && (
        <div css={{ gridColumn: '1 / -1' }}>
          <Alert severity="warning">{t('addressNotIdentified')}</Alert>
        </div>
      )}
      <div
        css={media({
          gridColumn: ['span 9 / span 9', 'span 8 / span 8', 'span 9 / span 9'],
        })}
      >
        <FormControl required={true} error={errors.route != null}>
          <InputLabel>{t('route')}</InputLabel>
          <AddressInput
            value={values.route ?? ''}
            country={countryCode}
            onChange={value => setValues({ route: value })}
            onSelect={address => {
              if (address) {
                setValues({
                  countryCode: address.countryCode,
                  route: address.route,
                  streetNumber: address.streetNumber,
                  locality: address.locality,
                  postcode: address.postcode,
                  state: address.state,
                  lat: address.lat,
                  lng: address.lng,
                });
              }
            }}
            onBlur={() => setTouched({ route: true })}
          />
          {errors.route != null && (
            <FormHelperText>{errors.route}</FormHelperText>
          )}
        </FormControl>
      </div>
      <div
        css={media({
          gridColumn: ['span 3 / span 3', 'span 4 / span 4', 'span 3 / span 3'],
        })}
      >
        <TextField
          label={t('streetNumber')}
          error={errors.streetNumber != null}
          helperText={errors.streetNumber}
          value={values.streetNumber ?? ''}
          onChange={event => setValues({ streetNumber: event.target.value })}
          onBlur={() => setTouched({ streetNumber: true })}
        />
      </div>
      <div css={media({ gridColumn: ['1 / -1', 'span 6 / span 6'] })}>
        <TextField
          label={t('postcode')}
          error={errors.postcode != null}
          helperText={errors.postcode}
          value={values.postcode ?? ''}
          onChange={event => setValues({ postcode: event.target.value })}
          onBlur={() => setTouched({ postcode: true })}
        />
      </div>
      <div css={media({ gridColumn: ['1 / -1', 'span 6 / span 6'] })}>
        <TextField
          label={t('locality')}
          error={errors.locality != null}
          helperText={errors.locality}
          value={values.locality ?? ''}
          onChange={event => setValues({ locality: event.target.value })}
          onBlur={() => setTouched({ locality: true })}
        />
      </div>
      <div css={media({ gridColumn: ['1 / -1', 'span 6 / span 6'] })}>
        <FormControl error={errors.state != null}>
          <TextField
            label={t('state')}
            required={true}
            error={errors.state != null}
            helperText={errors.state}
            value={values.state ?? ''}
            onChange={event => setValues({ state: event.target.value })}
            onBlur={() => setTouched({ state: true })}
          />
        </FormControl>
      </div>
      <div css={media({ gridColumn: ['1 / -1', 'span 6 / span 6'] })}>
        <FormControl error={errors.countryCode != null} required={true}>
          <InputLabel>{t('country')}</InputLabel>
          <CountryInput
            value={values.countryCode}
            onChange={countryCode => setValues({ countryCode })}
            onBlur={() => setTouched({ countryCode: true })}
          />
          {errors.countryCode != null && (
            <FormHelperText>{errors.countryCode}</FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
};

const PropertyTypeForm = ({ root, formik, disabledFields }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <TextField
        select={true}
        label={t('category')}
        value={values.mainType ?? ''}
        onChange={event => {
          setValues({
            mainType: event.target.value,
            propertyTypeId: null,
            propertyTypeName: null,
          });
        }}
        error={errors.mainType != null}
        helperText={errors.mainType}
        disabled={disabledFields?.includes('mainType')}
      >
        <MenuItem value="HOUSE">{t('house')}</MenuItem>
        <MenuItem value="APPT">{t('apartment')}</MenuItem>
        <MenuItem value="PROP">{t('land')}</MenuItem>
        <MenuItem value="PARK">{t('parking')}</MenuItem>
        <MenuItem value="INDUS">{t('commercial')}</MenuItem>
        <MenuItem value="GASTRO">{t('hotellerie')}</MenuItem>
      </TextField>
      <TextField
        select={true}
        label={t('type')}
        value={values.propertyTypeName ?? ''}
        onChange={event => {
          const propertyByName = root.propertyTypes?.find(
            type => type.name === event.target.value,
          );

          if (propertyByName) {
            setValues({
              propertyTypeId: propertyByName.id,
              propertyTypeName: event.target.value,
            });
          }
        }}
        error={errors.propertyTypeId != null}
        helperText={errors.propertyTypeId}
        required={values.mainType != null}
        disabled={
          disabledFields?.includes('propertyTypeName') ||
          values.mainType == null
        }
      >
        {(root.propertyTypes ?? [])
          .filter(
            type => type.mainType === values.mainType && type.type === 'simple',
          )
          .map(type => (
            <MenuItem value={type.name} key={type.id}>
              {type.label ?? null}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
};

const PlotNumberForm = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <TextField
        label={t('plotNumberLabel')}
        value={values.plotNumber}
        error={errors.plotNumber != null}
        helperText={errors.plotNumber ?? null}
        onChange={event => setValues({ plotNumber: event.target.value })}
        onBlur={() => setTouched({ plotNumber: true })}
      />
    </div>
  );
};

const SurfacesForm = ({
  formik,
  isCommercial,
  isMultiFamily,
  isBuilding,
  isLand,
  disabledFields,
}) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      {isMultiFamily && (
        <>
          <FormControl error={errors.residentialSurface != null}>
            <InputLabel>{t('residentialSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.residentialSurface}
              onChange={newValue => setValues({ residentialSurface: newValue })}
              onBlur={() => setTouched({ residentialSurface: true })}
            />
            {errors.residentialSurface != null && (
              <FormHelperText>{errors.residentialSurface}</FormHelperText>
            )}
          </FormControl>
        </>
      )}
      {isCommercial && (
        <FormControl error={errors.commercialSurface != null}>
          <InputLabel>{t('commercialSurface')}</InputLabel>
          <SquareInput
            decimalScale={2}
            value={values.commercialSurface}
            onChange={newValue => setValues({ commercialSurface: newValue })}
            onBlur={() => setTouched({ commercialSurface: true })}
          />
          {errors.commercialSurface != null && (
            <FormHelperText>{errors.commercialSurface}</FormHelperText>
          )}
        </FormControl>
      )}
      {!isBuilding && !isLand && (
        <>
          <FormControl error={errors.livingSurface != null}>
            <InputLabel>{t('livingSurface')}</InputLabel>
            <SquareInput
              disabled={disabledFields?.includes('livingSurface')}
              decimalScale={2}
              value={values.livingSurface}
              onChange={newValue => setValues({ livingSurface: newValue })}
              onBlur={() => setTouched({ livingSurface: true })}
            />
            {errors.livingSurface != null && (
              <FormHelperText>{errors.livingSurface}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.usableSurface != null}>
            <InputLabel>{t('usableSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.usableSurface}
              onChange={newValue => setValues({ usableSurface: newValue })}
              onBlur={() => setTouched({ usableSurface: true })}
            />
            {errors.usableSurface != null && (
              <FormHelperText>{errors.usableSurface}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.grossFloorSurface != null}>
            <InputLabel>{t('grossFloorSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.grossFloorSurface}
              onChange={newValue => setValues({ grossFloorSurface: newValue })}
              onBlur={() => setTouched({ grossFloorSurface: true })}
            />
            {errors.grossFloorSurface != null && (
              <FormHelperText>{errors.grossFloorSurface}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.balconySurface != null}>
            <InputLabel>{t('balconySurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.balconySurface}
              onChange={newValue => setValues({ balconySurface: newValue })}
              onBlur={() => setTouched({ balconySurface: true })}
            />
            {errors.balconySurface != null && (
              <FormHelperText>{errors.balconySurface}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.terraceSurface != null}>
            <InputLabel>{t('terraceSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.terraceSurface}
              onChange={newValue => setValues({ terraceSurface: newValue })}
              onBlur={() => setTouched({ terraceSurface: true })}
            />
            {errors.terraceSurface != null && (
              <FormHelperText>{errors.terraceSurface}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.gardenSurface != null}>
            <InputLabel>{t('gardenSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.gardenSurface}
              onChange={newValue => setValues({ gardenSurface: newValue })}
              onBlur={() => setTouched({ gardenSurface: true })}
            />
            {errors.gardenSurface != null && (
              <FormHelperText>{errors.gardenSurface}</FormHelperText>
            )}
          </FormControl>
        </>
      )}
      <FormControl
        error={errors.landSurface != null}
        disabled={values.mainType === 'APPT'}
      >
        <InputLabel>{t('landSurface')}</InputLabel>
        <SquareInput
          disabled={disabledFields?.includes('landSurface')}
          decimalScale={2}
          value={values.landSurface}
          onChange={newValue => setValues({ landSurface: newValue })}
          onBlur={() => setTouched({ landSurface: true })}
        />
        {errors.landSurface != null && (
          <FormHelperText>{errors.landSurface}</FormHelperText>
        )}
      </FormControl>
      {!isBuilding && !isLand && (
        <>
          <FormControl error={errors.basementSurface != null}>
            <InputLabel>{t('basementSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.basementSurface}
              onChange={newValue => setValues({ basementSurface: newValue })}
              onBlur={() => setTouched({ basementSurface: true })}
            />
            {errors.basementSurface != null && (
              <FormHelperText>{errors.basementSurface}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.weightedFloorSurface != null}>
            <InputLabel>{t('weightedFloorSurface')}</InputLabel>
            <SquareInput
              decimalScale={2}
              value={values.weightedFloorSurface}
              onChange={newValue =>
                setValues({ weightedFloorSurface: newValue })
              }
              onBlur={() => setTouched({ weightedFloorSurface: true })}
            />
            {errors.weightedFloorSurface != null && (
              <FormHelperText>{errors.weightedFloorSurface}</FormHelperText>
            )}
          </FormControl>
        </>
      )}
      {!isLand && (
        <>
          <FormControl error={errors.buildingVolume != null}>
            <InputLabel>{t('buildingVolume')}</InputLabel>
            <CubeInput
              decimalScale={2}
              value={values.buildingVolume}
              onChange={newValue => setValues({ buildingVolume: newValue })}
              onBlur={() => setTouched({ buildingVolume: true })}
            />
            {errors.buildingVolume != null && (
              <FormHelperText>{errors.buildingVolume}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={errors.builtSurface != null}>
            <InputLabel>{t('builtSurface')}</InputLabel>
            <SquareInput
              disabled={disabledFields?.includes('builtSurface')}
              decimalScale={2}
              value={values.builtSurface}
              onChange={newValue => setValues({ builtSurface: newValue })}
              onBlur={() => setTouched({ builtSurface: true })}
            />
            {errors.builtSurface != null && (
              <FormHelperText>{errors.builtSurface}</FormHelperText>
            )}
          </FormControl>
        </>
      )}
    </div>
  );
};

const UnitsForm = ({ formik, isCommercial, isMultiFamily }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      {isMultiFamily && (
        <FormControl error={errors.numberOfResidentialUnits != null}>
          <InputLabel shrink={true}>{t('numberOfResidentialUnits')}</InputLabel>
          <CounterInput
            step={1}
            min={1}
            max={500}
            value={values.numberOfResidentialUnits}
            onChange={value => setValues({ numberOfResidentialUnits: value })}
            onBlur={() => setTouched({ numberOfResidentialUnits: true })}
          />
          {errors.numberOfResidentialUnits != null && (
            <FormHelperText>{errors.numberOfResidentialUnits}</FormHelperText>
          )}
        </FormControl>
      )}
      {isCommercial && (
        <FormControl error={errors.numberOfCommercialUnits != null}>
          <InputLabel shrink={true}>{t('numberOfCommercialUnits')}</InputLabel>
          <CounterInput
            step={1}
            min={1}
            max={500}
            value={values.numberOfCommercialUnits}
            onChange={value => setValues({ numberOfCommercialUnits: value })}
            onBlur={() => setTouched({ numberOfCommercialUnits: true })}
          />
          {errors.numberOfCommercialUnits != null && (
            <FormHelperText>{errors.numberOfCommercialUnits}</FormHelperText>
          )}
        </FormControl>
      )}
    </div>
  );
};

const RoomsForm = ({ formik, disabledFields }) => {
  const { t, countryCode } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <FormControl error={errors.numberOfRooms != null}>
        <InputLabel shrink={true}>{t('rooms')}</InputLabel>
        <CounterInput
          disabled={disabledFields?.includes('numberOfRooms')}
          step={countryCode !== 'CH' ? 1 : 0.5}
          min={values.mainType === 'PROP' ? 0 : 1}
          max={50}
          value={values.numberOfRooms}
          onChange={value => setValues({ numberOfRooms: value })}
          onBlur={() => setTouched({ numberOfRooms: true })}
        />
        {errors.numberOfRooms != null && (
          <FormHelperText>{errors.numberOfRooms}</FormHelperText>
        )}
      </FormControl>
      <FormControl error={errors.numberOfBedrooms != null}>
        <InputLabel shrink={true}>{t('bedrooms')}</InputLabel>
        <CounterInput
          disabled={disabledFields?.includes('numberOfBedrooms')}
          step={1}
          min={0}
          max={50}
          value={values.numberOfBedrooms}
          onChange={value => setValues({ numberOfBedrooms: value })}
          onBlur={() => setTouched({ numberOfBedrooms: true })}
        />
        {errors.numberOfBedrooms != null && (
          <FormHelperText>{errors.numberOfBedrooms}</FormHelperText>
        )}
      </FormControl>
      <FormControl error={errors.numberOfBathrooms != null}>
        <InputLabel shrink={true}>{t('bathrooms')}</InputLabel>
        <CounterInput
          step={1}
          min={0}
          max={20}
          value={values.numberOfBathrooms}
          onChange={value => setValues({ numberOfBathrooms: value })}
          onBlur={() => setTouched({ numberOfBathrooms: true })}
        />
        {errors.numberOfBathrooms != null && (
          <FormHelperText>{errors.numberOfBathrooms}</FormHelperText>
        )}
      </FormControl>
      <FormControl error={errors.numberOfToilets != null}>
        <InputLabel shrink={true}>{t('separatedToilets')}</InputLabel>
        <CounterInput
          step={1}
          min={0}
          max={20}
          value={values.numberOfToilets}
          onChange={value => setValues({ numberOfToilets: value })}
          onBlur={() => setTouched({ numberOfToilets: true })}
        />
        {errors.numberOfToilets != null && (
          <FormHelperText>{errors.numberOfToilets}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

const FloorsForm = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <FormControl error={errors.numberOfFloors != null}>
        <InputLabel shrink={true}>{t('numberOfFloors')}</InputLabel>
        <CounterInput
          step={1}
          min={0}
          max={50}
          value={values.numberOfFloors}
          onChange={value => setValues({ numberOfFloors: value })}
          onBlur={() => setTouched({ numberOfFloors: true })}
        />
        {errors.numberOfFloors != null && (
          <FormHelperText>{errors.numberOfFloors}</FormHelperText>
        )}
      </FormControl>
      {values.mainType === 'APPT' && (
        <>
          <FormControl error={errors.floorOfFlat != null}>
            <InputLabel shrink={true}>{t('floorOfFlat')}</InputLabel>
            <CounterInput
              step={1}
              min={0}
              max={50}
              value={values.floorOfFlat}
              onChange={value => setValues({ floorOfFlat: value })}
              onBlur={() => setTouched({ floorOfFlat: true })}
            />
            {errors.floorOfFlat != null && (
              <FormHelperText>{errors.floorOfFlat}</FormHelperText>
            )}
          </FormControl>
          <TextField
            select={true}
            label={t('side')}
            value={values.side ?? ''}
            onChange={event => setValues({ side: (event.target.value: any) })}
            error={errors.side != null}
            helperText={errors.side}
          >
            <MenuItem value={'exterior'}>{t('exterior')}</MenuItem>
            <MenuItem value={'interior'}>{t('interior')}</MenuItem>
          </TextField>
          <TextField
            select={true}
            label={t('orientation')}
            value={values.orientation || ''}
            onChange={event =>
              setValues({ orientation: (event.target.value: any) })
            }
            error={errors.orientation != null}
            helperText={errors.orientation}
          >
            <MenuItem value={'N'}>{t('northShort')}</MenuItem>
            <MenuItem value={'NE'}>{t('northEastShort')}</MenuItem>
            <MenuItem value={'E'}>{t('eastShort')}</MenuItem>
            <MenuItem value={'SE'}>{t('southEastShort')}</MenuItem>
            <MenuItem value={'S'}>{t('southShort')}</MenuItem>
            <MenuItem value={'SW'}>{t('southWestShort')}</MenuItem>
            <MenuItem value={'W'}>{t('westShort')}</MenuItem>
            <MenuItem value={'NW'}>{t('northWestShort')}</MenuItem>
          </TextField>
        </>
      )}
    </div>
  );
};

const ParkingForm = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <FormControl error={errors.numberOfIndoorParkings != null}>
        <InputLabel shrink={true}>{t('indoorParking')}</InputLabel>
        <CounterInput
          step={1}
          min={0}
          value={values.numberOfIndoorParkings}
          onChange={value => setValues({ numberOfIndoorParkings: value })}
          onBlur={() => setTouched({ numberOfIndoorParkings: true })}
        />
        {errors.numberOfIndoorParkings != null && (
          <FormHelperText>{errors.numberOfIndoorParkings}</FormHelperText>
        )}
      </FormControl>
      <FormControl error={errors.numberOfOutdoorParkings != null}>
        <InputLabel shrink={true}>{t('outdoorParking')}</InputLabel>
        <CounterInput
          step={1}
          min={0}
          value={values.numberOfOutdoorParkings}
          onChange={value => setValues({ numberOfOutdoorParkings: value })}
          onBlur={() => setTouched({ numberOfOutdoorParkings: true })}
        />
        {errors.numberOfOutdoorParkings != null && (
          <FormHelperText>{errors.numberOfOutdoorParkings}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

const HeatingCoolingForm = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, setValues } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <TextField
        select={true}
        label={t('heating')}
        value={values.heating}
        onChange={event => setValues({ heating: (event.target.value: any) })}
      >
        <MenuItem value="none">{t('none')}</MenuItem>
        <MenuItem value="central">{t('centralHeating')}</MenuItem>
        <MenuItem value="district">{t('districtHeating')}</MenuItem>
        <MenuItem value="oil">{t('heatingOil')}</MenuItem>
        <MenuItem value="gas">{t('gas')}</MenuItem>
        <MenuItem value="electric">{t('electric')}</MenuItem>
        <MenuItem value="wood">{t('wood')}</MenuItem>
        <MenuItem value="solar">{t('solarCollector')}</MenuItem>
        <MenuItem value="heat_pump">{t('heatPump')}</MenuItem>
        <MenuItem value="other">{t('other')}</MenuItem>
      </TextField>
      <TextField
        select={true}
        label={t('heatingDistribution')}
        value={values.heatingDistribution}
        onChange={event =>
          setValues({ heatingDistribution: (event.target.value: any) })
        }
      >
        <MenuItem value="radiators">{t('radiators')}</MenuItem>
        <MenuItem value="floor">{t('floorHeating')}</MenuItem>
        <MenuItem value="forced_air">{t('forcedAir')}</MenuItem>
        <MenuItem value="stove">{t('stove')}</MenuItem>
        <MenuItem value="other">{t('other')}</MenuItem>
      </TextField>
      <TextField
        select={true}
        label={t('cooling')}
        value={values.cooling}
        onChange={event => setValues({ cooling: (event.target.value: any) })}
      >
        <MenuItem value="air_conditioning">{t('airConditioning')}</MenuItem>
        <MenuItem value="ceiling_fans">{t('ceilingFans')}</MenuItem>
        <MenuItem value="none">{t('none')}</MenuItem>
      </TextField>
    </div>
  );
};

const EnergyEfficencySection = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;
  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
      })}
    >
      <TextField
        select={true}
        label={t('energyConsumptionClass')}
        css={{
          '& .MuiFormLabel-root': {
            overflow: 'hidden',
            maxWidth: 'calc(100% - 40px)',
          },
        }}
        value={values.energyConsumptionClass}
        onChange={event =>
          setValues({ energyConsumptionClass: event.target.value })
        }
      >
        <MenuItem value={'a'}>{'A'}</MenuItem>
        <MenuItem value={'b'}>{'B'}</MenuItem>
        <MenuItem value={'c'}>{'C'}</MenuItem>
        <MenuItem value={'d'}>{'D'}</MenuItem>
        <MenuItem value={'e'}>{'E'}</MenuItem>
        <MenuItem value={'f'}>{'F'}</MenuItem>
        <MenuItem value={'g'}>{'G'}</MenuItem>
        <MenuItem value={'not_set'}>{t('notSet')}</MenuItem>
      </TextField>

      <TextField
        select={true}
        css={{
          '& .MuiFormLabel-root': {
            overflow: 'hidden',
            maxWidth: 'calc(100% - 40px)',
          },
        }}
        label={t('gasEmissionsClass')}
        value={values.gasEmissionsClass}
        onChange={event => setValues({ gasEmissionsClass: event.target.value })}
      >
        <MenuItem value={'a'}>{'A'}</MenuItem>
        <MenuItem value={'b'}>{'B'}</MenuItem>
        <MenuItem value={'c'}>{'C'}</MenuItem>
        <MenuItem value={'d'}>{'D'}</MenuItem>
        <MenuItem value={'e'}>{'E'}</MenuItem>
        <MenuItem value={'f'}>{'F'}</MenuItem>
        <MenuItem value={'g'}>{'G'}</MenuItem>
        <MenuItem value={'not_set'}>{t('notSet')}</MenuItem>
      </TextField>

      <FormControl error={errors.consumption != null}>
        <InputLabel
          css={{
            overflow: 'hidden',
            maxWidth: 'calc(100% - 100px)',
          }}
        >
          {t('consumption')}
        </InputLabel>
        <EnergyConsumptionInput
          decimalScale={0}
          value={values.consumption ?? ''}
          onChange={newValue => setValues({ consumption: newValue })}
          onBlur={() => setTouched({ consumption: true })}
        />
        {errors.consumption != null && (
          <FormHelperText>{errors.consumption}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={errors.emissions != null}>
        <InputLabel>{t('emissions')}</InputLabel>
        <EmissionsInput
          decimalScale={0}
          value={values.emissions ?? ''}
          onChange={newValue => setValues({ emissions: newValue })}
          onBlur={() => setTouched({ emissions: true })}
        />
        {errors.emissions != null && (
          <FormHelperText>{errors.emissions}</FormHelperText>
        )}
      </FormControl>

      <FormControl>
        <InputLabel>{t('estimateRefYear')}</InputLabel>
        <YearInput
          value={values.energyReferenceYear ?? ''}
          onChange={newValue => setValues({ energyReferenceYear: newValue })}
          onBlur={() => setTouched({ energyReferenceYear: true })}
        />
      </FormControl>

      <FormControl>
        <InputLabel>{t('inspectionDate')}</InputLabel>
        <DateInput
          value={values?.energyInspectionDate ?? ''}
          onChange={energyInspectionDate => setValues({ energyInspectionDate })}
        />
      </FormControl>

      <FormControl error={errors.estimatedLowerEnergyCost != null}>
        <InputLabel>{t('minEstimatedCostYear')}</InputLabel>
        <PriceInput
          value={values.estimatedLowerEnergyCost ?? ''}
          onChange={newValue =>
            setValues({ estimatedLowerEnergyCost: newValue })
          }
          onBlur={() => setTouched({ estimatedLowerEnergyCost: true })}
        />
        {errors.estimatedLowerEnergyCost != null && (
          <FormHelperText>{errors.estimatedLowerEnergyCost}</FormHelperText>
        )}
      </FormControl>

      <FormControl error={errors.estimatedHigherEnergyCost != null}>
        <InputLabel>{t('maxEstimatedCostYear')}</InputLabel>
        <PriceInput
          value={values.estimatedHigherEnergyCost ?? ''}
          onChange={newValue =>
            setValues({ estimatedHigherEnergyCost: newValue })
          }
          onBlur={() => setTouched({ estimatedHigherEnergyCost: true })}
        />
        {errors.estimatedHigherEnergyCost != null && (
          <FormHelperText>{errors.estimatedHigherEnergyCost}</FormHelperText>
        )}
      </FormControl>

      <TextField
        label={t('certificateVersion')}
        value={values.energyCertificateVersion}
        onChange={event =>
          setValues({ energyCertificateVersion: event.target.value })
        }
        onBlur={() => setTouched({ energyCertificateVersion: true })}
      />
    </div>
  );
};

const ConstructionForm = ({ formik, isChTenant, disabledFields }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
      })}
    >
      <FormControl error={errors.constructionYear != null}>
        <InputLabel>{t('constructionYear')}</InputLabel>
        <YearInput
          value={values.constructionYear}
          disabled={disabledFields?.includes('constructionYear')}
          onChange={newValue => setValues({ constructionYear: newValue })}
          onBlur={() => setTouched({ constructionYear: true })}
        />
        {errors.constructionYear != null && (
          <FormHelperText>{errors.constructionYear}</FormHelperText>
        )}
      </FormControl>
      <FormControl error={errors.renovationYear != null}>
        <InputLabel>{t('renovationYear')}</InputLabel>
        <YearInput
          disabled={disabledFields?.includes('renovationYear')}
          value={values.renovationYear}
          onChange={newValue => setValues({ renovationYear: newValue })}
          onBlur={() => setTouched({ renovationYear: true })}
        />
        {errors.renovationYear != null && (
          <FormHelperText>{errors.renovationYear}</FormHelperText>
        )}
      </FormControl>
      <TextField
        select={true}
        label={t('buildingStandard')}
        value={values.buildingStandard}
        onChange={event => setValues({ buildingStandard: event.target.value })}
        error={errors.buildingStandard != null}
        helperText={errors.buildingStandard}
      >
        <MenuItem value={5}>{t('fiveExcellent')}</MenuItem>
        <MenuItem value={4}>{t('fourVeryGood')}</MenuItem>
        <MenuItem value={3}>{t('threeGood')}</MenuItem>
        <MenuItem value={2}>{t('twoFair')}</MenuItem>
        <MenuItem value={1}>{t('onePoor')}</MenuItem>
        <MenuItem value={null}>{t('notSet')}</MenuItem>
      </TextField>
      <TextField
        select={true}
        label={t('buildingCondition')}
        value={values.buildingCondition}
        onChange={event => setValues({ buildingCondition: event.target.value })}
        error={errors.buildingCondition != null}
        helperText={errors.buildingCondition}
      >
        <MenuItem value={5}>{t('fiveExcellent')}</MenuItem>
        <MenuItem value={4}>{t('fourVeryGood')}</MenuItem>
        <MenuItem value={3}>{t('threeGood')}</MenuItem>
        <MenuItem value={2}>{t('twoFair')}</MenuItem>
        <MenuItem value={1}>{t('onePoor')}</MenuItem>
        <MenuItem value={null}>{t('notSet')}</MenuItem>
      </TextField>
      {isChTenant === true && (
        <div>
          <TextField
            select={true}
            label={t('minergieCode')}
            value={values.minergieCode}
            onChange={event => setValues({ minergieCode: event.target.value })}
            error={errors.minergieCode != null}
            helperText={errors.minergieCode}
          >
            <MenuItem value={5}>{t('fiveMinergiePEco')}</MenuItem>
            <MenuItem value={4}>{t('fourMinergieEco')}</MenuItem>
            <MenuItem value={3}>{t('threeMinergieP')}</MenuItem>
            <MenuItem value={2}>{t('twoMinergie')}</MenuItem>
            <MenuItem value={1}>{t('oneNotMinergie')}</MenuItem>
            <MenuItem value={null}>{t('notSet')}</MenuItem>
          </TextField>
        </div>
      )}
    </div>
  );
};

const CostsForm = ({
  formik,
  isCommercial,
  isMultiFamily,
  isBuilding,
  isLand,
}) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;
  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      {isMultiFamily && (
        <>
          <TextField
            variant="filled"
            select={true}
            label={t('fullyRentedResidential')}
            value={values.isResidentialFullyRented ? 'true' : 'false'}
            onChange={event =>
              setValues({
                isResidentialFullyRented: event.target.value === 'true',
              })
            }
          >
            <MenuItem value="true">{t('yes')}</MenuItem>
            <MenuItem value="false">{t('no')}</MenuItem>
          </TextField>
          <FormControl error={errors.residentialYearlyRentalIncome != null}>
            <InputLabel>{t('actualRentalIncomeResidential')}</InputLabel>
            <PriceInput
              value={values.residentialYearlyRentalIncome}
              onChange={newValue =>
                setValues({ residentialYearlyRentalIncome: newValue })
              }
              onBlur={() => setTouched({ residentialYearlyRentalIncome: true })}
            />
            {errors.residentialYearlyRentalIncome != null && (
              <FormHelperText>
                {errors.residentialYearlyRentalIncome}
              </FormHelperText>
            )}
          </FormControl>
          {values.isResidentialFullyRented === false && (
            <>
              {isCommercial && <div />}
              <FormControl
                error={errors.residentialPotentialRentalIncome != null}
              >
                <InputLabel>{t('potentialRentalIncomeResidential')}</InputLabel>
                <PriceInput
                  value={values.residentialPotentialRentalIncome}
                  onChange={newValue =>
                    setValues({ residentialPotentialRentalIncome: newValue })
                  }
                  onBlur={() =>
                    setTouched({ residentialPotentialRentalIncome: true })
                  }
                />
                {errors.residentialPotentialRentalIncome != null && (
                  <FormHelperText>
                    {errors.residentialPotentialRentalIncome}
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
        </>
      )}

      {isCommercial && (
        <>
          <TextField
            variant="filled"
            select={true}
            label={t('fullyRentedCommercial')}
            value={values.isCommercialFullyRented ? 'true' : 'false'}
            onChange={event =>
              setValues({
                isCommercialFullyRented: event.target.value === 'true',
              })
            }
          >
            <MenuItem value="true">{t('yes')}</MenuItem>
            <MenuItem value="false">{t('no')}</MenuItem>
          </TextField>
          <FormControl error={errors.commercialYearlyRentalIncome != null}>
            <InputLabel>{t('actualRentalIncomeCommercial')}</InputLabel>
            <PriceInput
              value={values.commercialYearlyRentalIncome}
              onChange={newValue =>
                setValues({ commercialYearlyRentalIncome: newValue })
              }
              onBlur={() => setTouched({ commercialYearlyRentalIncome: true })}
            />
            {errors.commercialYearlyRentalIncome != null && (
              <FormHelperText>
                {errors.commercialYearlyRentalIncome}
              </FormHelperText>
            )}
          </FormControl>
          {values.isCommercialFullyRented === false && (
            <>
              {isMultiFamily && <div />}
              <FormControl
                error={errors.commercialPotentialRentalIncome != null}
              >
                <InputLabel>{t('potentialRentalIncomeCommercial')}</InputLabel>
                <PriceInput
                  value={values.commercialPotentialRentalIncome}
                  onChange={newValue =>
                    setValues({ commercialPotentialRentalIncome: newValue })
                  }
                  onBlur={() =>
                    setTouched({ commercialPotentialRentalIncome: true })
                  }
                />
                {errors.commercialPotentialRentalIncome != null && (
                  <FormHelperText>
                    {errors.commercialPotentialRentalIncome}
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
        </>
      )}

      {(isMultiFamily || isCommercial) && (
        <>
          <TextField
            variant="filled"
            select={true}
            label={t('incomeIncludesParking')}
            value={values.incomeIncludesParking ? 'true' : 'false'}
            onChange={event =>
              setValues({
                incomeIncludesParking: event.target.value === 'true',
              })
            }
          >
            <MenuItem value="true">{t('yes')}</MenuItem>
            <MenuItem value="false">{t('no')}</MenuItem>
          </TextField>
          {values.incomeIncludesParking === false && (
            <>
              <TextField
                variant="filled"
                select={true}
                label={t('fullyRentedParking')}
                value={values.isParkingFullyRented ? 'true' : 'false'}
                onChange={event =>
                  setValues({
                    isParkingFullyRented: event.target.value === 'true',
                  })
                }
              >
                <MenuItem value="true">{t('yes')}</MenuItem>
                <MenuItem value="false">{t('no')}</MenuItem>
              </TextField>
              <FormControl error={errors.parkingYearlyRentalIncome != null}>
                <InputLabel>{t('actualRentalIncomeParking')}</InputLabel>
                <PriceInput
                  value={values.parkingYearlyRentalIncome}
                  onChange={newValue =>
                    setValues({ parkingYearlyRentalIncome: newValue })
                  }
                  onBlur={() => setTouched({ parkingYearlyRentalIncome: true })}
                />
                {errors.parkingYearlyRentalIncome != null && (
                  <FormHelperText>
                    {errors.parkingYearlyRentalIncome}
                  </FormHelperText>
                )}
              </FormControl>
              {values.isParkingFullyRented === false && (
                <FormControl
                  error={errors.parkingPotentialRentalIncome != null}
                >
                  <InputLabel>{t('potentialRentalIncomeParking')}</InputLabel>
                  <PriceInput
                    value={values.parkingPotentialRentalIncome}
                    onChange={newValue =>
                      setValues({ parkingPotentialRentalIncome: newValue })
                    }
                    onBlur={() =>
                      setTouched({ parkingPotentialRentalIncome: true })
                    }
                  />
                  {errors.parkingPotentialRentalIncome != null && (
                    <FormHelperText>
                      {errors.parkingPotentialRentalIncome}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </>
          )}
        </>
      )}
      <FormControl>
        <InputLabel>{t('charges')}</InputLabel>
        <PriceInput
          value={values.charges}
          onChange={newValue => setValues({ charges: newValue })}
          onBlur={() => setTouched({ charges: true })}
        />
      </FormControl>
      {!isBuilding && !isLand && (
        <FormControl>
          <InputLabel>{t('renovationFund')}</InputLabel>
          <PriceInput
            value={values.renovationFund}
            onChange={newValue => setValues({ renovationFund: newValue })}
            onBlur={() => setTouched({ renovationFund: true })}
          />
        </FormControl>
      )}
    </div>
  );
};

const PointsOfInterestForm = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
      })}
    >
      <FormControl>
        <InputLabel>{t('kindergarten')}</InputLabel>
        <MeterInput
          value={values.distanceToKindergarten}
          onChange={newValue => setValues({ distanceToKindergarten: newValue })}
          onBlur={() => setTouched({ distanceToKindergarten: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('primarySchool')}</InputLabel>
        <MeterInput
          value={values.distanceToPrimarySchool}
          onChange={newValue =>
            setValues({ distanceToPrimarySchool: newValue })
          }
          onBlur={() => setTouched({ distanceToPrimarySchool: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('highSchool')}</InputLabel>
        <MeterInput
          value={values.distanceToHighSchool}
          onChange={newValue => setValues({ distanceToHighSchool: newValue })}
          onBlur={() => setTouched({ distanceToHighSchool: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('hospital')}</InputLabel>
        <MeterInput
          value={values.distanceToHospital}
          onChange={newValue => setValues({ distanceToHospital: newValue })}
          onBlur={() => setTouched({ distanceToHospital: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('supermarket')}</InputLabel>
        <MeterInput
          value={values.distanceToGroceryShop}
          onChange={newValue => setValues({ distanceToGroceryShop: newValue })}
          onBlur={() => setTouched({ distanceToGroceryShop: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('cityCenter')}</InputLabel>
        <MeterInput
          value={values.distanceToCityCenter}
          onChange={newValue => setValues({ distanceToCityCenter: newValue })}
          onBlur={() => setTouched({ distanceToCityCenter: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('trainStation')}</InputLabel>
        <MeterInput
          value={values.distanceToTrainStation}
          onChange={newValue => setValues({ distanceToTrainStation: newValue })}
          onBlur={() => setTouched({ distanceToTrainStation: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('airport')}</InputLabel>
        <MeterInput
          value={values.distanceToAirport}
          onChange={newValue => setValues({ distanceToAirport: newValue })}
          onBlur={() => setTouched({ distanceToAirport: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('publicTransport')}</InputLabel>
        <MeterInput
          value={values.distanceToPublicTransport}
          onChange={newValue =>
            setValues({ distanceToPublicTransport: newValue })
          }
          onBlur={() => setTouched({ distanceToPublicTransport: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('motorwayAccess')}</InputLabel>
        <MeterInput
          value={values.distanceToMotorway}
          onChange={newValue => setValues({ distanceToMotorway: newValue })}
          onBlur={() => setTouched({ distanceToMotorway: true })}
        />
      </FormControl>
      <FormControl>
        <InputLabel>{t('sportsCenter')}</InputLabel>
        <MeterInput
          value={values.distanceToSportsCenter}
          onChange={newValue => setValues({ distanceToSportsCenter: newValue })}
          onBlur={() => setTouched({ distanceToSportsCenter: true })}
        />
      </FormControl>
    </div>
  );
};

const FeaturesForm = ({ formik }) => {
  const { t } = useLocale();
  const { values, setValues } = formik;

  return (
    <Flex flexWrap="wrap">
      <Box flexGrow={1} p={2} css={{ minWidth: 220 }}>
        <FormControl component="fieldset">
          <FormLabel style={{ fontSize: '12px' }}>{t('equipment')}</FormLabel>
          <FormGroup>
            <FormControlLabel
              label={t('hasElevator')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasElevator}
                  onChange={event =>
                    setValues({ hasElevator: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasFireplace')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasFireplace}
                  onChange={event =>
                    setValues({ hasFireplace: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasSwimmingPool')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasSwimmingPool}
                  onChange={event =>
                    setValues({ hasSwimmingPool: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasCellar')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasCellar}
                  onChange={event =>
                    setValues({ hasCellar: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasAttic')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasAttic}
                  onChange={event =>
                    setValues({ hasAttic: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasStorageRoom')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasStorageRoom}
                  onChange={event =>
                    setValues({ hasStorageRoom: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasGym')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasGym}
                  onChange={event =>
                    setValues({ hasGym: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasSauna')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasSauna}
                  onChange={event =>
                    setValues({ hasSauna: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasWellnessArea')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasWellnessArea}
                  onChange={event =>
                    setValues({ hasWellnessArea: event.target.checked })
                  }
                />
              }
            />
          </FormGroup>
        </FormControl>
      </Box>

      <Box flexGrow={1} p={2} css={{ minWidth: 220 }}>
        <FormControl component="fieldset">
          <FormLabel style={{ fontSize: '12px' }}>{t('others')}</FormLabel>
          <FormGroup>
            <FormControlLabel
              label={t('isLuxury')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.isLuxury}
                  onChange={event =>
                    setValues({ isLuxury: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasView')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasView}
                  onChange={event =>
                    setValues({ hasView: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('isWheelchairAccessible')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.isWheelchairAccessible}
                  onChange={event =>
                    setValues({ isWheelchairAccessible: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('isChildFriendly')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.isChildFriendly}
                  onChange={event =>
                    setValues({ isChildFriendly: event.target.checked })
                  }
                />
              }
            />
            <FormControlLabel
              label={t('hasPhotovoltaicPanels')}
              control={
                <Checkbox
                  color="primary"
                  checked={values.hasPhotovoltaicPanels}
                  onChange={event =>
                    setValues({ hasPhotovoltaicPanels: event.target.checked })
                  }
                />
              }
            />
          </FormGroup>
        </FormControl>
      </Box>
      {['1-f', '2-f', '3-f', '4-f'].map(key => (
        <Box key={key} px={2} flexGrow={1} css={{ minWidth: 220 }} />
      ))}
    </Flex>
  );
};

const LocationForm = ({ formik }) => {
  const { t } = useLocale();
  const { media } = useSystem();
  const { values, errors, setValues, setTouched } = formik;

  return (
    <div
      css={media({
        display: 'grid',
        gap: 16,
        gridTemplateColumns: ['1fr', '1fr 1fr'],
      })}
    >
      <FormControl error={errors.microLocation != null}>
        <InputLabel shrink={true}>{t('microLocation')}</InputLabel>
        <CounterInput
          step={0.1}
          min={1}
          max={5}
          value={values.microLocation}
          onChange={value => setValues({ microLocation: value })}
          onBlur={() => setTouched({ microLocation: true })}
        />
        {errors.microLocation != null && (
          <FormHelperText>{errors.microLocation}</FormHelperText>
        )}
      </FormControl>
      <TextField
        select={true}
        label={t('microLocation')}
        value={
          values.microLocation != null && values.microLocation !== ''
            ? Math.round(Number.parseFloat(values.microLocation))
            : ''
        }
        onChange={event => setValues({ microLocation: event.target.value })}
        error={errors.microLocation != null}
        helperText={errors.microLocation}
      >
        <MenuItem value="5">{t('fiveExcellent')}</MenuItem>
        <MenuItem value="4">{t('fourVeryGood')}</MenuItem>
        <MenuItem value="3">{t('threeGood')}</MenuItem>
        <MenuItem value="2">{t('twoFair')}</MenuItem>
        <MenuItem value="1">{t('onePoor')}</MenuItem>
      </TextField>
      <FormControl error={errors.macroLocation != null}>
        <InputLabel shrink={true}>{t('macroLocation')}</InputLabel>
        <CounterInput
          step={0.1}
          min={1}
          max={5}
          value={values.macroLocation}
          onChange={value => setValues({ macroLocation: value })}
          onBlur={() => setTouched({ macroLocation: true })}
        />
        {errors.macroLocation != null && (
          <FormHelperText>{errors.macroLocation}</FormHelperText>
        )}
      </FormControl>
      <TextField
        select={true}
        label={t('macroLocation')}
        value={
          values.macroLocation != null && values.macroLocation !== ''
            ? Math.round(Number.parseFloat(values.macroLocation))
            : ''
        }
        onChange={event => setValues({ macroLocation: event.target.value })}
        error={errors.macroLocation != null}
        helperText={errors.macroLocation}
      >
        <MenuItem value="5">{t('fiveExcellent')}</MenuItem>
        <MenuItem value="4">{t('fourVeryGood')}</MenuItem>
        <MenuItem value="3">{t('threeGood')}</MenuItem>
        <MenuItem value="2">{t('twoFair')}</MenuItem>
        <MenuItem value="1">{t('onePoor')}</MenuItem>
      </TextField>
    </div>
  );
};

export const PropertyForm = (props: Props): React.Node => {
  const root = useFragment(
    graphql`
      fragment PropertyForm_root on Query {
        propertyTypes {
          id
          name
          label
          mainType
          type
        }
        tenantSettings {
          id
          countryCode
        }
      }
    `,
    props.root,
  );

  const property = useFragment(
    graphql`
      fragment PropertyForm_property on Property {
        id
        route
        streetNumber
        state
        postcode
        locality
        countryCode
        lat
        lng
        propertyType {
          id
          name
          label
          mainType
        }
        microLocation
        macroLocation
        residentialSurface
        commercialSurface
        livingSurface
        builtSurface
        usableSurface
        landSurface
        balconySurface
        gardenSurface
        terraceSurface
        grossFloorSurface
        basementSurface
        weightedFloorSurface
        numberOfFloors
        floorOfFlat
        side
        orientation
        heating
        heatingDistribution
        cooling
        numberOfRooms
        numberOfBedrooms
        numberOfBathrooms
        numberOfToilets
        numberOfIndoorParkings
        numberOfOutdoorParkings
        constructionYear
        renovationYear
        buildingStandard
        buildingCondition
        minergieCode
        buildingVolume
        hasElevator
        hasFireplace
        hasSwimmingPool
        hasStorageRoom
        hasCellar
        hasAttic
        hasPhotovoltaicPanels
        hasSauna
        hasGym
        hasWellnessArea
        isLuxury
        hasView
        isWheelchairAccessible
        isChildFriendly
        distanceToPublicTransport
        distanceToGroceryShop
        distanceToKindergarten
        distanceToPrimarySchool
        distanceToHighSchool
        distanceToMotorway
        distanceToHospital
        distanceToCityCenter
        distanceToAirport
        distanceToTrainStation
        distanceToSportsCenter
        charges
        renovationFund
        numberOfResidentialUnits
        numberOfCommercialUnits
        isResidentialFullyRented
        isCommercialFullyRented
        residentialPotentialRentalIncome
        residentialYearlyRentalIncome
        commercialPotentialRentalIncome
        commercialYearlyRentalIncome
        incomeIncludesParking
        isParkingFullyRented
        parkingPotentialRentalIncome
        parkingYearlyRentalIncome
        plotNumber
        emissions
        consumption
        energyReferenceYear
        estimatedLowerEnergyCost
        estimatedHigherEnergyCost
        energyCertificateVersion
        energyInspectionDate
        energyConsumptionClass
        gasEmissionsClass
      }
    `,
    props.property,
  );

  const {
    initialValues,
    holder,
    handleSubmit,
    retry,
    children,
    showAppraisalAlert,
  } = props;

  const { t } = useLocale();
  const { media } = useSystem();
  const [updateProperty, updating] = useMutation<PropertyFormUpdateMutation>(
    graphql`
      mutation PropertyFormUpdateMutation($input: UpsertPropertyInput!) {
        upsertProperty(input: $input) {
          property {
            ...PropertyForm_property
            appraisable
            formattedAddress
            updatedAt
          }
        }
      }
    `,
  );

  const isEsTenant = root.tenantSettings?.countryCode === 'ES';

  const formik = useFormik<PropertyFormState>({
    initialValues: getInitialValues(property, initialValues),
    validate: values =>
      validate({
        values,
        t,
        isEsTenant,
        disableValidation: props.disableValidation ?? false,
      }),
    onSubmit: values => {
      const payloadProperty = {
        id: property?.id,
        lat: values.lat,
        lng: values.lng,
        route: values.route,
        streetNumber: values.streetNumber,
        postcode: values.postcode,
        state: values.state,
        locality: values.locality,
        countryCode: values.countryCode,
        propertyTypeId: values.propertyTypeId,
        propertyTypeName: values.propertyTypeName,

        microLocation: number_of_string(values.microLocation),
        macroLocation: number_of_string(values.macroLocation),
        residentialSurface: number_of_string(values.residentialSurface),
        commercialSurface: number_of_string(values.commercialSurface),
        livingSurface: number_of_string(values.livingSurface),
        builtSurface: number_of_string(values.builtSurface),
        usableSurface: number_of_string(values.usableSurface),
        landSurface: number_of_string(values.landSurface),
        balconySurface: number_of_string(values.balconySurface),
        gardenSurface: number_of_string(values.gardenSurface),
        terraceSurface: number_of_string(values.terraceSurface),
        grossFloorSurface: number_of_string(values.grossFloorSurface),
        basementSurface: number_of_string(values.basementSurface),
        weightedFloorSurface: number_of_string(values.weightedFloorSurface),
        numberOfRooms: number_of_string(values.numberOfRooms),
        numberOfBedrooms: number_of_string(values.numberOfBedrooms),
        numberOfBathrooms: number_of_string(values.numberOfBathrooms),
        numberOfToilets: number_of_string(values.numberOfToilets),
        numberOfIndoorParkings: number_of_string(values.numberOfIndoorParkings),
        numberOfOutdoorParkings: number_of_string(
          values.numberOfOutdoorParkings,
        ),
        numberOfFloors: number_of_string(values.numberOfFloors),
        floorOfFlat: number_of_string(values.floorOfFlat),
        side: values.side,
        orientation: values.orientation,
        heating: values.heating,
        heatingDistribution: values.heatingDistribution,
        cooling: values.cooling,

        distanceToPublicTransport: number_of_string(
          values.distanceToPublicTransport,
        ),
        distanceToGroceryShop: number_of_string(values.distanceToGroceryShop),
        distanceToKindergarten: number_of_string(values.distanceToKindergarten),
        distanceToPrimarySchool: number_of_string(
          values.distanceToPrimarySchool,
        ),
        distanceToHighSchool: number_of_string(values.distanceToHighSchool),
        distanceToMotorway: number_of_string(values.distanceToMotorway),
        distanceToHospital: number_of_string(values.distanceToHospital),
        distanceToCityCenter: number_of_string(values.distanceToCityCenter),
        distanceToAirport: number_of_string(values.distanceToAirport),
        distanceToTrainStation: number_of_string(values.distanceToTrainStation),
        distanceToSportsCenter: number_of_string(values.distanceToSportsCenter),

        constructionYear: number_of_string(values.constructionYear),
        renovationYear: number_of_string(values.renovationYear),
        buildingStandard: number_of_string(values.buildingStandard),
        buildingCondition: number_of_string(values.buildingCondition),
        minergieCode: number_of_string(values.minergieCode),
        buildingVolume: number_of_string(values.buildingVolume),

        charges: number_of_string(values.charges),
        renovationFund: number_of_string(values.renovationFund),
        numberOfResidentialUnits: number_of_string(
          values.numberOfResidentialUnits,
        ),
        numberOfCommercialUnits: number_of_string(
          values.numberOfCommercialUnits,
        ),
        isResidentialFullyRented: values.isResidentialFullyRented,
        isCommercialFullyRented: values.isCommercialFullyRented,
        residentialPotentialRentalIncome: number_of_string(
          values.residentialPotentialRentalIncome,
        ),
        residentialYearlyRentalIncome: number_of_string(
          values.residentialYearlyRentalIncome,
        ),
        commercialPotentialRentalIncome: number_of_string(
          values.commercialPotentialRentalIncome,
        ),
        commercialYearlyRentalIncome: number_of_string(
          values.commercialYearlyRentalIncome,
        ),
        incomeIncludesParking: values.incomeIncludesParking,
        isParkingFullyRented: values.isParkingFullyRented,
        parkingPotentialRentalIncome: number_of_string(
          values.parkingPotentialRentalIncome,
        ),
        parkingYearlyRentalIncome: number_of_string(
          values.parkingYearlyRentalIncome,
        ),

        hasElevator: values.hasElevator,
        hasFireplace: values.hasFireplace,
        hasSwimmingPool: values.hasSwimmingPool,
        hasStorageRoom: values.hasStorageRoom,
        hasCellar: values.hasCellar,
        hasAttic: values.hasAttic,
        hasPhotovoltaicPanels: values.hasPhotovoltaicPanels,
        hasSauna: values.hasSauna,
        hasGym: values.hasGym,
        hasWellnessArea: values.hasWellnessArea,
        isLuxury: values.isLuxury,
        hasView: values.hasView,
        isWheelchairAccessible: values.isWheelchairAccessible,
        isChildFriendly: values.isChildFriendly,
        plotNumber: values.plotNumber,

        emissions: number_of_string(values.emissions ?? ''),
        consumption: number_of_string(values.consumption ?? ''),
        energyReferenceYear: number_of_string(values.energyReferenceYear ?? ''),
        estimatedLowerEnergyCost: number_of_string(
          values.estimatedLowerEnergyCost ?? '',
        ),
        estimatedHigherEnergyCost: number_of_string(
          values.estimatedHigherEnergyCost ?? '',
        ),
        energyCertificateVersion:
          values.energyCertificateVersion === ''
            ? null
            : values.energyCertificateVersion,
        energyInspectionDate: toISODateString(
          parseDate(values.energyInspectionDate ?? ''),
        ),
        energyConsumptionClass: values.energyConsumptionClass,
        gasEmissionsClass: values.gasEmissionsClass,
      };
      if (handleSubmit) {
        handleSubmit(payloadProperty, values);
      } else {
        // reset form to set initialValues which may be changed on server side
        updateProperty({
          variables: {
            input: {
              property: payloadProperty,
            },
          },
          onCompleted: () => {
            if (retry) {
              retry();
            }
            formik.resetForm();
          },
        });
      }
    },
  });

  const isModal = holder === 'big-modal';

  // TODO: indus_commercial_and_residential and house_multiple_dwelling have different mainTypes (house_multiple_dwelling is still HOUSE)
  // house_multiple_dwelling will be changed when https://github.com/realadvisor/realadvisor/issues/12458 is done
  const propertyTypeName = formik.values.propertyTypeName;
  const isMultiFamily = [
    'indus_commercial_and_residential',
    'house_multiple_dwelling',
  ].includes(propertyTypeName);
  const isCommercial = [
    'indus_commercial_and_residential',
    'indus_commercial',
  ].includes(propertyTypeName);
  const isBuilding = [
    'indus_commercial_and_residential',
    'house_multiple_dwelling',
    'indus_commercial',
  ].includes(propertyTypeName);
  const isLand = formik.values.mainType === 'PROP';
  const appraisalFieldsFilled = !getAppraisalRequirements(formik.values).some(
    field => Object.keys(formik.errors).includes(field),
  );

  const form = (
    <>
      <Form onSubmit={formik.submitForm} css={{ position: 'relative' }}>
        <ScrollToError errors={formik.errors} />
        {showAppraisalAlert === true && !appraisalFieldsFilled && (
          <Alert
            css={{
              position: 'sticky',
              top: 64,
              marginLeft: -16,
              marginRight: -16,
              zIndex: 2,
            }}
            severity={appraisalFieldsFilled ? 'success' : 'error'}
          >
            {appraisalFieldsFilled
              ? t('readyForAppraisal')
              : t('pleaseFillRequiredFieldsForAppraisal')}
          </Alert>
        )}
        <Box py={isModal ? 0 : 3} px={isModal ? 0 : [2, 3]}>
          <SectionHeading>{t('address')}</SectionHeading>
          <Box
            mb={4}
            css={media({
              display: 'grid',
              gap: 16,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
            })}
          >
            <AddressForm
              formik={formik}
              disabledFields={props.disabledFields}
            />
            <div
              css={media({
                minHeight: [240, 0],
                paddingTop: 'inherit',
              })}
            >
              <AddressLocationMap formik={formik} />
            </div>
          </Box>

          <Box mb={4}>
            <SectionHeading>{t('propertyType')}</SectionHeading>
            <PropertyTypeForm
              root={root}
              formik={formik}
              disabledFields={props.disabledFields}
            />

            {isLand && (
              <Box mt={3}>
                <PlotNumberForm formik={formik} />
              </Box>
            )}
          </Box>

          <Box mb={4}>
            <SectionHeading>{t('surfaces')}</SectionHeading>
            <SurfacesForm
              formik={formik}
              isCommercial={isCommercial}
              isMultiFamily={isMultiFamily}
              isBuilding={isBuilding}
              isLand={isLand}
              disabledFields={props.disabledFields}
            />
          </Box>

          {(isMultiFamily || isCommercial) && (
            <Box mb={4}>
              <SectionHeading>{t('units')}</SectionHeading>
              <UnitsForm
                formik={formik}
                isCommercial={isCommercial}
                isMultiFamily={isMultiFamily}
              />
            </Box>
          )}

          {!isBuilding && !isLand && (
            <Box mb={4}>
              <SectionHeading>{t('rooms')}</SectionHeading>
              <RoomsForm
                formik={formik}
                disabledFields={props.disabledFields}
              />
            </Box>
          )}

          {!isLand && (
            <>
              <Box mb={4}>
                <SectionHeading>
                  {formik.values.mainType === 'APPT'
                    ? t('floorsAndOrientation')
                    : t('floors')}
                </SectionHeading>
                <FloorsForm
                  formik={formik}
                  disabledFields={props.disabledFields}
                />
              </Box>

              <Box mb={4}>
                <SectionHeading>{t('parking')}</SectionHeading>
                <ParkingForm formik={formik} />
              </Box>
            </>
          )}

          {!isBuilding && !isLand && (
            <Box mb={4}>
              <SectionHeading>{t('heatingAndCooling')}</SectionHeading>
              <HeatingCoolingForm formik={formik} />
            </Box>
          )}

          {!isLand && root.tenantSettings?.countryCode !== 'CH' && (
            <Box mb={4}>
              <SectionHeading>{t('energyEfficiency')}</SectionHeading>
              <EnergyEfficencySection formik={formik} />
            </Box>
          )}

          {!isLand && (
            <>
              <Box mb={4}>
                <SectionHeading>{t('construction')}</SectionHeading>
                <ConstructionForm
                  formik={formik}
                  isChTenant={root.tenantSettings?.countryCode === 'CH'}
                  disabledFields={props.disabledFields}
                />
              </Box>
              <Box mb={4}>
                <SectionHeading>
                  {isBuilding ? t('incomeAndCosts') : t('costs')}
                </SectionHeading>
                <CostsForm
                  formik={formik}
                  isCommercial={isCommercial}
                  isMultiFamily={isMultiFamily}
                  isBuilding={isBuilding}
                  isLand={isLand}
                />
              </Box>
            </>
          )}

          <Box mb={4}>
            <SectionHeading>{t('pointsOfInterestNearby')}</SectionHeading>
            <PointsOfInterestForm formik={formik} />
          </Box>

          {!isLand && (
            <Box mb={4}>
              <SectionHeading>{t('features')}</SectionHeading>
              <FeaturesForm formik={formik} />
            </Box>
          )}

          <SectionHeading>{t('location')}</SectionHeading>
          <LocationForm formik={formik} />
        </Box>
      </Form>
    </>
  );

  return children
    ? children({
        form,
        submitForm: formik.submitForm,
        resetForm: formik.resetForm,
        changed: props.showAppraisalAlert === true ? true : formik.changed, // always enable submit button for appraisal validation mode
        valid: formik.valid,
        submitting: updating,
      })
    : form;
};
