import { useEffect, useRef, useState } from 'react';

import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import type {
  DateRange,
  PickersActionBarProps,
  PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { endOfDay, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';

import { useLocale } from '../../../../src/hooks/locale';
import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { FilterChip } from './FilterChip';
import { type QuickFilterProps } from './QuickFilters';

const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: 'Today',
    getValue: () => {
      const today = new Date();
      return [startOfDay(today), endOfDay(today)];
    },
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return [startOfDay(yesterday), endOfDay(yesterday)];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = new Date();
      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7);
      return [
        startOfWeek(lastWeek, { weekStartsOn: 1 }),
        endOfWeek(lastWeek, { weekStartsOn: 1 }),
      ];
    },
  },
  {
    label: 'This Month',
    getValue: () => {
      const today = new Date();
      return [
        startOfDay(new Date(today.getFullYear(), today.getMonth(), 1)),
        endOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
      ];
    },
  },
  {
    label: 'Last 30 days',
    getValue: () => {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setDate(today.getDate() - 30);
      return [startOfDay(lastMonth), endOfDay(today)];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = new Date();
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);
      return [
        startOfDay(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1)),
        endOfDay(
          new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0),
        ),
      ];
    },
  },
  {
    label: 'This Year',
    getValue: () => {
      const today = new Date();
      return [
        startOfDay(new Date(today.getFullYear(), 0, 1)),
        endOfDay(new Date(today.getFullYear(), 11, 31)),
      ];
    },
  },
];

const CustomToolBar = (props: any) => {
  const {
    value: [from, to],
    onRangePositionChange,
    onChange,
    ownerState: { onAccept },
  } = props;

  // Create refs for both date inputs
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);

  // Automatically focus the appropriate input when the range position changes
  useEffect(() => {
    if (props.rangePosition === 'start' && startInputRef.current) {
      startInputRef.current.focus();
    } else if (props.rangePosition === 'end' && endInputRef.current) {
      endInputRef.current.focus();
    }
  }, [props.rangePosition]);

  return (
    <form
      style={{ gridColumn: 2, display: 'flex' }}
      onSubmit={e => {
        e.preventDefault();
        onAccept();
      }}
    >
      <Box sx={{ flexGrow: 1, pt: 2, px: 4 }}>
        <DateField
          inputRef={startInputRef}
          autoFocus={props.rangePosition === 'start'}
          value={from}
          onChange={(date, { validationError }) => {
            if (!validationError) {
              onChange([date, to]);
            }
          }}
          variant="outlined"
          size="small"
          fullWidth
          onFocus={() => onRangePositionChange('start')}
        />
      </Box>
      <Box sx={{ flexGrow: 1, pt: 2, px: 4 }}>
        <DateField
          inputRef={endInputRef}
          autoFocus={props.rangePosition === 'end'}
          value={to}
          onChange={(date, { validationError }) => {
            if (!validationError) {
              onChange([from, date]);
            }
          }}
          variant="outlined"
          size="small"
          fullWidth
          onFocus={() => onRangePositionChange('end')}
        />
      </Box>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export const DateFilter = ({
  label,
  path,
  where,
  addWhereClause,
  getValueFromPath,
  deleteWhereClause,
  queryParamsScope,
  disabled,
}: QuickFilterProps) => {
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);
  const { dateLocale } = useLocale();
  const initialValue = getValueFromPath(path, where);
  const startDate = initialValue?._gte ? new Date(initialValue?._gte) : null;
  const endDate = initialValue?._lte ? new Date(initialValue?._lte) : null;
  const [range, setRange] = useState<DateRange<Date>>([startDate, endDate]);
  const [rangePosition, setRangePosition] = useState<'start' | 'end'>('start');

  useEffect(() => {
    const newValues = getValueFromPath(path, where);
    const newStartDate = newValues?._gte ? new Date(newValues?._gte) : null;
    const newEndDate = newValues?._lte ? new Date(newValues?._lte) : null;
    setRange([newStartDate, newEndDate]);
  }, [where, getValueFromPath, path]);

  const handleAccept = (handleClose: () => void) => {
    let newWhere = deleteWhereClause(where, path, true);
    const _gte = range[0] ? startOfDay(range[0]) : null;
    const _lte = range[1] ? endOfDay(range[1]) : null;
    if (_gte) {
      newWhere = addWhereClause(newWhere, [...path, '_gte'], _gte);
    }
    if (_lte) {
      newWhere = addWhereClause(newWhere, [...path, '_lte'], _lte);
    }
    setFiltersParams(newWhere);
    handleClose();
  };

  const labelValueStart = range[0] ? format(range[0], 'd MMM yyyy') : '...';
  const labelValueEnd = range[1] ? format(range[1], 'd MMM yyyy') : '...';
  const labelValue =
    range[0] || range[1] ? `${labelValueStart} - ${labelValueEnd}` : null;
  const displayLabel = labelValue ? [label, labelValue].join(': ') : label;

  const handleDelete =
    range[0] || range[1]
      ? () => {
          setRange([null, null]);
          setFiltersParams(deleteWhereClause(where, path, true));
        }
      : undefined;

  return (
    <FilterChip
      label={displayLabel}
      onDelete={handleDelete}
      icon={<DateRangeIcon />}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <Paper elevation={3} sx={{ borderRadius: 3, mt: 0.5 }}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={dateLocale}
          >
            <StaticDateRangePicker
              calendars={2}
              rangePosition={rangePosition}
              onRangePositionChange={setRangePosition}
              value={range}
              slots={{
                toolbar: CustomToolBar,
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                  onChange: setRange,
                },
                actionBar: {
                  onCancel: handleClose,
                  onAccept: () => handleAccept(handleClose),
                } as PickersActionBarProps,
              }}
              onChange={([start, end]) => {
                setRange([start, end]);
              }}
              sx={{ borderRadius: 3 }}
            />
          </LocalizationProvider>
        </Paper>
      )}
    />
  );
};
