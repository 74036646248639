import { useRef, useState } from 'react';

import {
  Edit,
  EditOff,
  FileDownload,
  FileUpload,
  FilterAltOffOutlined,
  FilterAltOutlined,
  MoreHoriz,
  Refresh,
  SearchOutlined,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  useMediaQuery,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { Link, type To } from 'react-router-dom';

import {
  type FilterProps,
  Filters,
} from '../apollo/components/filters/Filters';
import { type FiltersTableDef } from '../apollo/components/table/TableFilters';
import { useScopedSearchParams } from '../apollo/utils/navigation';
import { useLocale } from '../src/hooks/locale';

export interface ImportDialogProps {
  open: boolean;
  onImportSuccess: () => void;
  onImporterClose: () => void;
}

export interface ExportDialogProps {
  where: any;
  open: boolean;
  onExporterClose: () => void;
}

export interface ListToolbarProps {
  tableName: string;
  quickFilters?: FilterProps['quickFilters'];
  tableFiltersTables?: FiltersTableDef[];
  viewLinks?: {
    name: string;
    icon: React.ReactNode;
  }[];
  searchComponent?: React.ReactNode;
  newLink?: To;
  actions?: React.ReactNode;
  moreActions?: React.ReactNode;
  bulkActions?: React.ReactNode;
  ImportDialog?: React.ElementType<ImportDialogProps>;
  ExportDialog?: React.ElementType<ExportDialogProps>;
  refetch?: () => void;
  queryParamsScope?: string;
  defaultFilterTypeExpanded?: 'v1' | 'v2' | 'quick';
}

export const ListToolbar = ({
  tableName,
  quickFilters,
  tableFiltersTables,
  viewLinks,
  searchComponent,
  newLink,
  actions,
  moreActions,
  bulkActions,
  ImportDialog,
  ExportDialog,
  refetch,
  queryParamsScope,
  defaultFilterTypeExpanded,
}: ListToolbarProps) => {
  const { t } = useLocale();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const [searchParams, setSearchParams] =
    useScopedSearchParams(queryParamsScope);
  const where = JSON.parse(searchParams.get('where') || '{}');
  const isEditing = searchParams.get('edit') === 'true';

  const [showSearch, setShowSearch] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const [expandedFilterType, setExpandedFilterType] = useState<
    'v1' | 'v2' | 'quick' | null
  >(defaultFilterTypeExpanded ?? null);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          width: '100%',
        }}
      >
        {viewLinks && !(isMobile && showSearch) && !isEditing && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              p: 1,
              gap: 1,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {viewLinks.map(({ name, icon }) => (
              <IconButton
                key={name}
                onClick={() => {
                  searchParams.set('view', name);
                  searchParams.delete('limit');
                  searchParams.delete('offset');
                  setSearchParams(searchParams);
                }}
              >
                {icon}
              </IconButton>
            ))}
          </Box>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Stack
            direction="row"
            sx={{
              flexGrow: 1,
              maxWidth: 600,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {!isEditing && (
              <ClickAwayListener onClickAway={() => setShowSearch(false)}>
                <Stack
                  direction={'row'}
                  gap={1}
                  sx={{ flexGrow: 1, justifyContent: 'flex-end' }}
                >
                  {searchComponent && !showSearch && (
                    <IconButton onClick={() => setShowSearch(!showSearch)}>
                      <SearchOutlined />
                    </IconButton>
                  )}

                  {searchComponent && showSearch && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                      }}
                    >
                      {searchComponent}
                    </Box>
                  )}
                </Stack>
              </ClickAwayListener>
            )}
            {quickFilters && !(isMobile && showSearch) && !isEditing && (
              <IconButton
                onClick={() =>
                  !expandedFilterType
                    ? setExpandedFilterType('quick')
                    : setExpandedFilterType(null)
                }
              >
                <Badge
                  badgeContent={Object.keys(where).length}
                  color="secondary"
                >
                  <FilterAltOutlined />
                </Badge>
              </IconButton>
            )}
            {refetch && !(isMobile && showSearch) && !isEditing && (
              <IconButton onClick={() => refetch()}>
                <Refresh />
              </IconButton>
            )}
            {!(isMobile && showSearch) && !isEditing && (
              <IconButton
                onClick={() => setMenuOpen(!menuOpen)}
                ref={menuAnchorRef}
              >
                <MoreHoriz />
              </IconButton>
            )}
          </Stack>

          {newLink && !(isMobile && showSearch) && !isEditing && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={newLink}
            >
              {t('New')}
            </Button>
          )}
          {isEditing && bulkActions}
          {isEditing && (
            <Button
              onClick={() => {
                searchParams.delete('edit');
                setSearchParams(searchParams);
              }}
              startIcon={<EditOff />}
            >
              {t('Finish')}
            </Button>
          )}
        </Box>
        {actions}
      </Box>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        // Align the popper to the right side of the anchor element
        placement="bottom-end"
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  onClick={() => setMenuOpen(false)}
                >
                  {tableFiltersTables && (
                    <MenuItem
                      onClick={() => {
                        setExpandedFilterType(
                          expandedFilterType === 'v1' ? null : 'v1',
                        );
                      }}
                    >
                      <ListItemIcon>
                        <FilterAltOutlined />
                      </ListItemIcon>
                      {t('Advanced filters v1')}
                    </MenuItem>
                  )}
                  {tableName && (
                    <MenuItem
                      onClick={() => {
                        setExpandedFilterType(
                          expandedFilterType === 'v2' ? null : 'v2',
                        );
                      }}
                    >
                      <ListItemIcon>
                        <FilterAltOutlined />
                      </ListItemIcon>
                      {t('Advanced filters v2')}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      searchParams.delete('where');
                      setSearchParams(searchParams);
                      //setFilterType(null);
                    }}
                  >
                    <ListItemIcon>
                      <FilterAltOffOutlined />
                    </ListItemIcon>
                    {t('Clear filters')}
                  </MenuItem>
                  {ImportDialog || ExportDialog ? <Divider /> : null}
                  {ImportDialog && (
                    <MenuItem onClick={() => setShowImportDialog(true)}>
                      <ListItemIcon>
                        <FileUpload />
                      </ListItemIcon>
                      {t('Import')}
                    </MenuItem>
                  )}
                  {ExportDialog && (
                    <MenuItem onClick={() => setShowExportDialog(true)}>
                      <ListItemIcon>
                        <FileDownload />
                      </ListItemIcon>
                      {t('Export')}
                    </MenuItem>
                  )}
                  {bulkActions && (
                    <>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          searchParams.set('edit', 'true');
                          setSearchParams(searchParams);
                        }}
                      >
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        {t('Bulk edit')}
                      </MenuItem>
                    </>
                  )}
                  {refetch && (
                    <>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          refetch();
                        }}
                      >
                        <ListItemIcon>
                          <Refresh />
                        </ListItemIcon>
                        {t('Refresh')}
                      </MenuItem>
                    </>
                  )}
                  {moreActions && <Divider />}
                  {moreActions}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {ImportDialog && (
        <ImportDialog
          open={showImportDialog}
          onImporterClose={() => setShowImportDialog(false)}
          onImportSuccess={() => {
            setShowImportDialog(false);
          }}
        />
      )}
      {ExportDialog && (
        <ExportDialog
          open={showExportDialog}
          where={where}
          onExporterClose={() => setShowExportDialog(false)}
        />
      )}
      <Filters
        tableName={tableName}
        expandedFilterType={expandedFilterType}
        tableFiltersTables={tableFiltersTables}
        quickFilters={quickFilters}
        queryParamsScope={queryParamsScope}
      />
    </>
  );
};
