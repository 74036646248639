import { gql, useQuery } from '@apollo/client';
import { Abc } from '@mui/icons-material';

import { useFiltersSearchParams } from '../useFiltersSearchParams';

import { FilterChip } from './FilterChip';
import type { AutocompleteItem, QuickFilterProps } from './QuickFilters';
import {
  AutocompletePaper,
  AutocompleteTextField,
  FilterAutocomplete,
} from './QuickFilters';

export const StringFilter = ({
  label,
  path,
  displayedColumn,
  where,
  filter,
  addWhereClause,
  deleteWhereClause,
  getValueFromPath,
  getTypeFromPath,
  onChange,
  queryParamsScope,
  disabled,
}: QuickFilterProps) => {
  const parentPath = path.slice(0, -2);
  const parentType = getTypeFromPath(parentPath);
  if (!parentType) {
    throw new Error('Parent type not found');
  }
  const tableName = parentType?.name.split('_bool_exp')[0];
  const columnName = path[path.length - 2];
  const [, setFiltersParams] = useFiltersSearchParams(queryParamsScope);

  const defaultValue: string[] | undefined = getValueFromPath(path, where);

  const GET_COLUMN_VALUES = gql`
    query GetColumnValues($distinct_on: [${tableName}_select_column!], $where: ${tableName}_bool_exp!) {
      ${tableName}(distinct_on: $distinct_on, limit: 100, where: $where) {
        ${displayedColumn ?? ''}
        ${columnName}
      }
    }
  `;

  // allow filtering list of options
  const whereFilter = filter
    ? addWhereClause({}, filter.path, filter.value)
    : {};

  const { data } = useQuery(GET_COLUMN_VALUES, {
    variables: {
      distinct_on: [columnName],
      where: whereFilter,
    },
  });
  const columnValues:
    | Record<string, string>[]
    | Record<string, string[]>[]
    | undefined = data?.[tableName];
  const availableValues =
    columnValues?.map(row => row[displayedColumn ?? columnName] || 'null') ??
    [];

  const options: AutocompleteItem[] = Array.from(
    new Set(availableValues.flat()),
  );
  const valuesAreArray = Array.isArray(columnValues?.[0]?.[columnName]);

  // if defaultValue is not null, we need to map it to displayed column value
  // so that it can be displayed in the chip
  const defaultValueDisplay: string[] =
    (valuesAreArray
      ? defaultValue
      : defaultValue?.map(v => {
          const row = columnValues?.find(row => row[columnName] === v);
          const displayVal = row?.[displayedColumn ?? columnName];
          return displayVal == null ? 'null' : displayVal.toString();
        })) ?? [];

  const handleChange = (value: AutocompleteItem[]) => {
    let newWhere = deleteWhereClause(where, path, true);
    if (value.length > 0) {
      // map displayed column value to actual column value
      // we can have multiple values selected
      const values = valuesAreArray
        ? value
        : value.map(v => {
            const row = columnValues?.find(
              row => row[displayedColumn ?? columnName] === v,
            );
            return row?.[columnName]?.toString();
          });

      newWhere = addWhereClause(newWhere, path, values);
      newWhere = onChange?.(values, newWhere) ?? newWhere;
    } else {
      newWhere = onChange?.(null, newWhere) ?? newWhere;
    }
    setFiltersParams(newWhere);
  };

  const handleDelete =
    defaultValueDisplay?.length > 0 ? () => handleChange([]) : undefined;

  const chipLabel = (() => {
    if (!defaultValueDisplay) {
      return label;
    }
    switch (defaultValueDisplay?.length) {
      case 0:
        return label;
      case 1:
        return `${label}: ${defaultValueDisplay[0]}`;
      default:
        return `${label}: ${defaultValueDisplay[0]} +${
          defaultValueDisplay.length - 1
        }`;
    }
  })();

  return (
    <FilterChip
      label={chipLabel}
      icon={<Abc />}
      onDelete={handleDelete}
      disabled={disabled?.(where) ?? false}
      renderFilter={({ handleClose }) => (
        <FilterAutocomplete
          options={options}
          onClose={handleClose}
          PaperComponent={AutocompletePaper}
          onChange={(_e, value) => handleChange(value)}
          value={defaultValueDisplay ?? []}
          renderInput={AutocompleteTextField}
        />
      )}
    />
  );
};
